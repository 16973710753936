import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ShieldApiService } from 'src/app/service/shield-api.service';

@Component({
  selector: 'app-deferred-doc-dialog',
  templateUrl: './deferred-doc-dialog.component.html',
  styleUrls: ['./deferred-doc-dialog.component.scss']
})
export class DeferredDocDialogComponent implements OnInit {

  remarks : string;
  deferredDate = new FormControl('');
  todayDate = new Date();
  
  constructor(private shieldService : ShieldApiService, @Inject(MAT_DIALOG_DATA) public data : any, private tostrService : ToastrService, public dialogRef : MatDialogRef<DeferredDocDialogComponent>) { }

  ngOnInit(): void {
  }

  deferredDoc(){
    let obj = {
      deferredDate : this.deferredDate.value
    }
    this.dialogRef.close(obj)
  }

}
