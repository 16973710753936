<header class="shieldFinanceHeadWrap">
  <div class="lftBlock">
    <i (click)="menuClick()" class="ri-menu-2-line hamburger-sidebar mr-10"></i>
    <a class="shieldLogo">
      <img src="/assets/img/shield.png" />
    </a>
  </div>
  <div class="rhtBlock">
    <!-- <div class="srchBoxContent">
      <i class="ri-search-line"></i>
      <input type="text" placeholder="Search" />
    </div> -->
    <div class="shieldUser ml-10 "[matMenuTriggerFor]="logout">
      <span class="sUserName">{{userProfile}}</span>
    </div>
    <mat-menu #logout="matMenu">
      <button mat-menu-item (click)="logOut()">Logout</button>
    </mat-menu>
  </div>
</header>

<div #sidebar class="atom-sidebar close" (mouseover)="AddMenuClick()" (mouseout)="removeMenuClick()">
  <ul class="nav-links">
    <li>
      <a (click)="navigateToListing()">
        <i class="ri-article-fill active"></i>Dashboard
      </a>
    </li>
  </ul>
</div>

<router-outlet></router-outlet>