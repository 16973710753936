import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import { RejectDialogComponent } from '../reject-dialog/reject-dialog.component';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-doc-list-action',
  templateUrl: './doc-list-action.component.html',
  styleUrls: ['./doc-list-action.component.scss']
})
export class DocListActionComponent implements OnInit {

  dataSource: any = new MatTableDataSource();
  displayedColumns: string[] = [];
  objectData: any = {};

  constructor(private dialogRef: MatDialogRef<DocListActionComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private tostrService : ToastrService, private shieldService : ShieldApiService, private dialog : MatDialog) { }

  ngOnInit(): void {
    if (this.data.isDirector) {
      this.displayedColumns = ['dirName','docName','status','doc','action'];
      this.getDirectorTableData();
    } else if (this.data.isBankStatement) {
      this.displayedColumns = ['bankName', 'status', 'doc', 'action'];
      this.getBankStatementTableData();
    }
  }

  getDirectorTableData() {
    const tableDataList = [];
    if(this.data && this.data.documentList && this.data.documentList.value && this.data.documentList.value.documents){
      const dataList = this.data.documentList.value.documents;
      for (const key of Object.keys(dataList)) {
        let obj = {
          name: key,
          value: dataList[key]
        }
        tableDataList.push(obj);
  
      }
      this.dataSource = tableDataList;
    }
    console.log("DATASOURCE DIRECTOR",this.dataSource)
  }

  getBankStatementTableData() {
    if (this.data.documentList && this.data.documentList.value && this.data.documentList.value.bankStatements && this.data.documentList.value.bankStatements.length > 0) {
      this.dataSource = this.data.documentList.value.bankStatements;
    }
    console.log(this.dataSource)
  }

  closeDialog() {
    this.dialogRef.close();
  }
  
  reUploadFile(file: any, type? : string) {
    // Trigger file input click to change the file
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.onchange = (event: any) => {
      const newFile = event.target.files[0];
      this.reUpload(newFile, file, type);
    };
    fileInput.click();
  }

  reUpload(file: any, element: any, type? : any) {
    const formData = new FormData();
    if(type !== 'director'){
      formData.append('docType', element.type);
      formData.append('oldDocId', element.documentId);
    } else {
      formData.append('docType', element.value.type);
      formData.append('oldDocId', element.value.documentId);
    }
    formData.append('laneTaskId', this.data.laneTaskId);
    formData.append('file', file);
    
    this.shieldService.uploadFile(formData).subscribe((res: any) => {
      this.tostrService.success("File Uploded SuccessFull!");
      this.dialogRef.close(true);
    })
  }

  removeFile(file: any) {
    let obj = {
      documentId : file.documentId,
      documentName : file.type,
      referenceId : file.id,
      id : this.data.laneTaskId
    }
    this.shieldService.deleteDocFile(obj).subscribe((res : any) =>{
      this.tostrService.success("File Deleted Sucessfully!");
      this.dialogRef.close(true);
    })
  }

  downloadFile(id: string) {

  }
  acceptDoc(element :any, status : string) {
    let obj = {
      documentType: element.type,
      status: status,
      laneTaskId: this.data.laneTaskId,
      documentId : element.documentId
    };
    this.shieldService.updateLead(obj).subscribe({
      next: (resp: any) => {
        this.tostrService.success(`Approved Successfully!`);
      },
      complete: () => {
        this.dialogRef.close();
      },
    });
  }

  rejectDialog(element : any , status : string){
    const dialogRef = this.dialog.open(RejectDialogComponent ,{
      disableClose : true,
      width: '500px',
      height: '250px',
      data : {
        element : element,
        laneTaskId : this.data.laneTaskId,
        isListingRejection : false
      }
    });
    dialogRef.afterClosed().subscribe((res : any) =>{
      if(res){
        this.dialogRef.close();
      }
    })
  }

  downloadDocument(id : string){
    this.shieldService.downloadSingleUploadDocument(id).subscribe((res : any) =>{
      this.downloadSheetCommonMethod(res);
    })
  };
  downloadSheetCommonMethod(res: any) {
		var contentDispositionHeader = res.headers.get('content-disposition')
		let resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1]
		let fileName = resultDownload.replace(/"/g, '');
    this.tostrService.success("Downloaded Successfully !");
		saveAs(res.body, fileName);
		return fileName;
	}
}
