import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppUrls } from 'src/app/constant/app.url.const';
import { OAuthTokenConst } from 'src/app/constant/oauth.const';
import { JwtService } from 'src/app/service/jwt.service';
import { OAuthService } from 'src/app/service/oauth.service';
import { environment } from 'src/environments/environment';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SideBarModule } from '../lead-listing/side-bar.module';
import { JwtInterceptor } from 'src/app/interceptor/jwt.interceptor';
import { ShieldApiService } from 'src/app/service/shield-api.service';

@Component({
	standalone: false,
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
	microsoftLoginUrl = `${environment.oauthServerUrl}${environment.microsoftAuthPath}`;
  accessToken:any
	constructor(
		private readonly activatedRoute: ActivatedRoute,
		private readonly oauthService: OAuthService,
		private readonly jwtService: JwtService,
		private readonly router: Router,
    private shieldService: ShieldApiService,
	) {}

	ngOnInit(): void {
		this.accessToken = this.jwtService.getToken();
		if (this.accessToken && this.jwtService.isTokenValid(this.accessToken)) {
			this.redirectToDashboard();
			return;
		}
		this.activatedRoute.queryParamMap.subscribe((queryParamMap) => {
			const tokenId = queryParamMap.get(OAuthTokenConst.TOKEN_ID);
			if (
				tokenId &&
				(!this.accessToken || !this.jwtService.isTokenValid(this.accessToken))
			) {
				this.oauthService
					.getTokenBuyTokenId(tokenId)
					.subscribe((oauthToken) => {
						this.oauthService.setUserStateWithToken(oauthToken);
					});
			}
		});
	}
  goToLogin(){
    this.accessToken ? this.shieldService.hide() : this.shieldService.show();
  }
	redirectToDashboard() {
		this.router.navigateByUrl(AppUrls.DASHBOARD);
	}
}
