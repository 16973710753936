import { Component, Input, OnInit, ViewChild } from '@angular/core'
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ThemePalette } from '@angular/material/core'
import { MatDialog } from '@angular/material/dialog'
import { MatMenuTrigger } from '@angular/material/menu'
import { ActivatedRoute } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { Observable, Subscription } from 'rxjs'
import { ShieldApiService } from 'src/app/service/shield-api.service'
import { saveAs } from 'file-saver'
import { FileDialougeComponent } from '../file-dialouge/file-dialouge.component'

export interface Task {
	name: string
	completed: boolean
	color: ThemePalette
	subtasks?: Task[]
}
@Component({
	selector: 'app-deatils-and-documents',
	templateUrl: './deatils-and-documents.component.html',
	styleUrls: ['./deatils-and-documents.component.scss'],
})
export class DeatilsAndDocumentsComponent implements OnInit {
	@ViewChild('menuTrigger') menuTrigger: MatMenuTrigger
	@Input() data: Observable<any>
	private dataSubscription: Subscription
	states = this.shieldService.statesOfIndia
	showSave: boolean = true
	fieldEnable: boolean = true
	sidPidName = ''
	bankList = BANK_LIST
	file: any
	files: File[] = []
	folder: { name: string; filesNew: File[] } | null = null
	auditFolder: { name: string; filesNew: File[] } | null = null
	salesFolder: { name: string; filesNew: File[] } | null = null
	stockFolder: { name: string; filesNew: File[] } | null = null
	debtorFolder: { name: string; filesNew: File[] } | null = null
	creditorFolder: { name: string; filesNew: File[] } | null = null
	bankFolder: { name: string; filesNew: File[] } | null = null
	sacntionFolder: { name: string; filesNew: File[] } | null = null
	commercialFolder: { name: string; filesNew: File[] } | null = null
	gstFolder: { name: string; filesNew: File[] } | null = null
	aomoFolder: { name: string; filesNew: File[] } | null = null
	cibilFolder: { name: string; filesNew: File[] } | null = null
	borrowerFolder: { name: string; filesNew: File[] } | null = null
	promoterFolder: { name: string; filesNew: File[] } | null = null
	borrowerCustomerFolder: { name: string; filesNew: File[] } | null = null
	borrowerSupplierFolder: { name: string; filesNew: File[] } | null = null
	ccStatementFolder: { name: string; filesNew: File[] } | null = null
	debtFolder: { name: string; filesNew: File[] } | null = null
	monthlyPaymentFolder: { name: string; filesNew: File[] } | null = null
	saralFolder: { name: string; filesNew: File[] } | null = null
	computationFolder: { name: string; filesNew: File[] } | null = null
	pAndLFolder: { name: string; filesNew: File[] } | null = null
	shareHoldingFolder: { name: string; filesNew: File[] } | null = null
	provisionalFinancialFolder: { name: string; filesNew: File[] } | null = null
	vintageTableFolder: { name: string; filesNew: File[] } | null = null
	ccUtilizationFolder: { name: string; filesNew: File[] } | null = null
	debtorCreditorMovementFolder: { name: string; filesNew: File[] } | null = null
	cmaProjectionsFolder: { name: string; filesNew: File[] } | null = null
	isProgressVisible = false
	panelOpenState = true
	detailForm: FormGroup
	dataSource3: Array<any> = []
	laneData: any
	displayColumns3: Array<string> = ['BuyerName', 'BuyerAddress', 'BuyerRating', 'BuyerIndusrty', 'BuyerturnOver', 'LimitRequested', 'TendorDays', 'Action']
	laneTaskId: any
	directorId: any
	tabName: string
	newLeadLaneData: any
	aoaAndIncomeCheck: boolean
	propritorCheck: boolean
	shareName:String = '';
	showCompanyPan: String = ''
	showCin: boolean = false
	showQuestion: boolean = false
	showMultiUpload:boolean = true;
	selectedDirector: any
	minDate: Date
	isEligibleToOnboard: boolean = false
	isReadyForCosent: boolean = false
	selectedLaneId: any
	laneIdList: any
	nextLane: boolean = false
	selectedTabIndex: number = 0
	private laneTaskIdSubscription: Subscription = new Subscription()

	constructor(public dialog: MatDialog, private fb: FormBuilder, public toasterService: ToastrService, private activatedRoute: ActivatedRoute, public shieldService: ShieldApiService) {
		const today = new Date()
		this.minDate = new Date(today.getFullYear() - 80, today.getMonth(), today.getDate())
	}

	task: Task = {
		name: 'Checl All',
		completed: false,
		color: 'primary',
		subtasks: [
			{ name: 'Check 1', completed: false, color: 'primary' },
			{ name: 'Check 2', completed: false, color: 'primary' },
			{ name: 'Check 3', completed: false, color: 'primary' },
		],
	}

	allComplete: boolean = false

	someComplete(): boolean {
		return this.task.subtasks.filter((t) => t.completed).length > 0 && !this.allComplete
	}

	setAll(completed: boolean) {
		this.allComplete = completed
		this.task.subtasks.forEach((t) => (t.completed = completed))
	}

	updateAllComplete() {
		this.allComplete = this.task.subtasks != null && this.task.subtasks.every((t) => t.completed)
	}

	cancel() {
		this.menuTrigger.closeMenu()
	}

	multiFileUpload(event: any) {
		const newFiles = Array.from(event.target.files) as File[]
		this.files = this.files.concat(newFiles)
	}

	removeFolder() {
		this.folder = null
		this.auditFolder = null
		this.salesFolder = null
		this.stockFolder = null
		this.debtorFolder = null
		this.creditorFolder = null
		this.sacntionFolder = null
		this.commercialFolder = null
		this.gstFolder = null
		this.aomoFolder = null
		this.cibilFolder = null
		this.bankFolder = null
		this.borrowerFolder = null;
		this.promoterFolder = null
		this.borrowerCustomerFolder = null
		this.borrowerSupplierFolder = null
		this.ccStatementFolder = null
		this.debtFolder = null
		this.monthlyPaymentFolder = null
		this.saralFolder = null
		this.computationFolder = null
		this.pAndLFolder = null
		this.shareHoldingFolder = null
		this.provisionalFinancialFolder = null
		this.vintageTableFolder = null
		this.ccUtilizationFolder = null
		this.debtorCreditorMovementFolder = null
		this.cmaProjectionsFolder = null
	}

	openDialog(keyWord: any): void {
		const dialogRef = this.dialog.open(FileDialougeComponent, {
			width: '500px',
			data: { dataType: keyWord, laneTaskId: this.laneTaskId, editable: this.fieldEnable },
			panelClass: 'file-dialog-container',
		})

		dialogRef.afterClosed().subscribe((result) => {
			this.fetchdata(this.laneTaskId)
		})
	}
	openDialogueBank(index: number) {
		const dialogRef = this.dialog.open(FileDialougeComponent, {
			width: '500px',
			data: { dataType: 'BANK_STATEMENTS', laneTaskId: this.laneTaskId, id: this.bankDetails.at(index).get('id').value, editable: this.fieldEnable },
			panelClass: 'file-dialog-container',
		})

		dialogRef.afterClosed().subscribe((result) => {
			this.fetchdata(this.laneTaskId)
		})
	}

	ngOnInit() {
		this.dataSubscription = this.data.subscribe((data) => {
			if (data) {
				this.laneTaskId = data.laneTaskId
				this.fetchdata(this.laneTaskId)
			}
		})
		this.initialiseForm()
		this.companyPanReplicate()
		this.typeOfcompanyReplicate()
		this.getTabValue(0)
	}

	initialiseForm() {
		this.detailForm = this.fb.group({
			companyDetails: this.fb.group({
				supplierName: [{ value: null, disabled: true }, Validators.required],
				typeOfCompany: [null, Validators.required],
				natureOfBusiness: [null, Validators.required],
				companyPAN: [null, [Validators.required]],
				companyPANFile: [null, Validators.required],
				cinNo: [null],
				cinNoFile: [null],
				gstinNo: [null, [Validators.required]],
				gstinNoFile: [null, Validators.required],
			}),
			businessUserDetails: this.fb.array([]),
			documentationsDTO: this.fb.group({
				businessVintageDTO: this.fb.group({
					typeOfCompany: [],
					gstCertificate: [],
					gstCertificateFile: [],
					udhyamAadharCertificate: [null, [Validators.pattern(/^[A-Z]{5}-[A-Z]{2}-\d{2}-\d{7}$/)]],
					udhyamAadharCertificateFile: [],
					incorporationCertificateFile: [],
				}),
				companyDocuments: this.fb.group({
					auditedStatment: [[]],
					salesPurchaseOrder: [[]],
					stockStatement: [[]],
					debtorStatement: [[]],
					creditorStatement: [[]],
					// bankStatements: [],
					sanctionLetters: [[]],
					commTransactionDoc: [[]],
					gstReturns: [[]],
					aoaAndMoa: [[]],
					cibilData: [[]],
					borrowerNote: [[]],
					promoterNote: [[]],
					borrowerCustomerLedger: [[]],
					borrowerSupplierLedger: [[]],
					ccStatment: [[]],
					debtPosition: [[]],
					monthlyRepayment: [[]],
					saralCopy: [[]],
					computationIncome: [[]],
					plBalanceSheet: [[]],
					shareHoldingPattern: [[]],
					provisionalFinancial: [[]],
					vintageTable: [[]],
					ccUtilization: [[]],
					debtorCreditorMonthlyMovement: [[]],
					cmaProjections: [[]],
				}),
				bankDetails: this.fb.array([]),
				residenceDocuments: this.fb.group({
					waterBillFile: [null],
					electricBillFile: [null],
					ownerShipProofDoc: [null],
				}),
				creditQuestionaire: this.fb.group({
					isBaseLocationBeyond100km: [null],
					isRatingBelowBBplus: [null],
					isTradingEntity: [null],
					isRelyingOnGovtEntitiesForSales: [null],
					hasActiveOperationsLessThan36Months: [null],
					hasNetworthLessThan2_50Cr: [null],
					hasOperatingScaleLessThan15Cr: [null],
					hasNegativeEBITDAInLast2FY: [null],
				}),
			}),
		})
	}
	multiFileUploadFolder(event: any, type: any) {
		const newFiles = Array.from(event.target.files) as File[]
		if (type == 'AUDITED_STATEMENT') {
			if (!this.auditFolder) {
				this.auditFolder = { name: 'Folder', filesNew: [] }
			}
			this.auditFolder.filesNew = this.auditFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.auditFolder.filesNew, 'bulk upload', type)
		} else if (type == 'SALES_OR_PURCHASE_ORDER') {
			if (!this.salesFolder) {
				this.salesFolder = { name: 'Folder', filesNew: [] }
			}
			this.salesFolder.filesNew = this.salesFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.salesFolder.filesNew, 'bulk upload', type)
		} else if (type == 'STOCK_STATEMENT') {
			if (!this.stockFolder) {
				this.stockFolder = { name: 'Folder', filesNew: [] }
			}
			this.stockFolder.filesNew = this.stockFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.stockFolder.filesNew, 'bulk upload', type)
		} else if (type == 'DEBTOR_STATEMENT') {
			if (!this.debtorFolder) {
				this.debtorFolder = { name: 'Folder', filesNew: [] }
			}
			this.debtorFolder.filesNew = this.debtorFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.debtorFolder.filesNew, 'bulk upload', type)
		} else if (type == 'CREDITOR_STATEMENT') {
			if (!this.creditorFolder) {
				this.creditorFolder = { name: 'Folder', filesNew: [] }
			}
			this.creditorFolder.filesNew = this.creditorFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.creditorFolder.filesNew, 'bulk upload', type)
		} else if (type == 'SANCTION_LETTERS') {
			if (!this.sacntionFolder) {
				this.sacntionFolder = { name: 'Folder', filesNew: [] }
			}
			this.sacntionFolder.filesNew = this.sacntionFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.sacntionFolder.filesNew, 'bulk upload', type)
		} else if (type == 'COMMERCIAL_TRANSACTION') {
			if (!this.commercialFolder) {
				this.commercialFolder = { name: 'Folder', filesNew: [] }
			}
			this.commercialFolder.filesNew = this.commercialFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.commercialFolder.filesNew, 'bulk upload', type)
		} else if (type == 'GST_RETURNS') {
			if (!this.gstFolder) {
				this.gstFolder = { name: 'Folder', filesNew: [] }
			}
			this.gstFolder.filesNew = this.gstFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.gstFolder.filesNew, 'bulk upload', type)
		} else if (type == 'AOA_AND_MOA') {
			if (!this.aomoFolder) {
				this.aomoFolder = { name: 'Folder', filesNew: [] }
			}
			this.aomoFolder.filesNew = this.aomoFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.aomoFolder.filesNew, 'bulk upload', type)
		} else if (type == 'CIBIL_DATA') {
			if (!this.cibilFolder) {
				this.cibilFolder = { name: 'Folder', filesNew: [] }
			}
			this.cibilFolder.filesNew = this.cibilFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.cibilFolder.filesNew, 'bulk upload', type)
		} else if (type == 'BORROWER_ENTITY_NOTE') {
			if (!this.borrowerFolder) {
				this.borrowerFolder = { name: 'Folder', filesNew: [] }
			}
			this.borrowerFolder.filesNew = this.borrowerFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.borrowerFolder.filesNew, 'bulk upload', type)
		} else if (type == 'PROMOTER_BACKGROUND_NOTE') {
			if (!this.promoterFolder) {
				this.promoterFolder = { name: 'Folder', filesNew: [] }
			}
			this.promoterFolder.filesNew = this.promoterFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.promoterFolder.filesNew, 'bulk upload', type)
		} else if (type == 'BORROWER_CUSTOMER_LEDGER') {
			if (!this.borrowerCustomerFolder) {
				this.borrowerCustomerFolder = { name: 'Folder', filesNew: [] }
			}
			this.borrowerCustomerFolder.filesNew = this.borrowerCustomerFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.borrowerCustomerFolder.filesNew, 'bulk upload', type)
		} else if (type == 'BORROWER_SUPPLIER_LEDGER') {
			if (!this.borrowerSupplierFolder) {
				this.borrowerSupplierFolder = { name: 'Folder', filesNew: [] }
			}
			this.borrowerSupplierFolder.filesNew = this.borrowerSupplierFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.borrowerSupplierFolder.filesNew, 'bulk upload', type)
		} else if (type == 'CC_STATEMENT') {
			if (!this.ccStatementFolder) {
				this.ccStatementFolder = { name: 'Folder', filesNew: [] }
			}
			this.ccStatementFolder.filesNew = this.ccStatementFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.ccStatementFolder.filesNew, 'bulk upload', type)
		} else if (type == 'DEBT_POSITION') {
			if (!this.debtFolder) {
				this.debtFolder = { name: 'Folder', filesNew: [] }
			}
			this.debtFolder.filesNew = this.debtFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.debtFolder.filesNew, 'bulk upload', type)
		} else if (type == 'MONTHLY_REPAYMENT') {
			if (!this.monthlyPaymentFolder) {
				this.monthlyPaymentFolder = { name: 'Folder', filesNew: [] }
			}
			this.monthlyPaymentFolder.filesNew = this.monthlyPaymentFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.monthlyPaymentFolder.filesNew, 'bulk upload', type)
		} else if (type == 'COMPUTATION_INCOME') {
			if (!this.computationFolder) {
				this.computationFolder = { name: 'Folder', filesNew: [] }
			}
			this.computationFolder.filesNew = this.computationFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.computationFolder.filesNew, 'bulk upload', type)
		} else if (type == 'SARAL_COPY') {
			if (!this.saralFolder) {
				this.saralFolder = { name: 'Folder', filesNew: [] }
			}
			this.saralFolder.filesNew = this.saralFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.saralFolder.filesNew, 'bulk upload', type)
		} else if (type == 'PL_BALANCE_SHEET') {
			if (!this.pAndLFolder) {
				this.pAndLFolder = { name: 'Folder', filesNew: [] }
			}
			this.pAndLFolder.filesNew = this.pAndLFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.pAndLFolder.filesNew, 'bulk upload', type)
		} else if (type == 'SHARE_HOLDING_PATTERN') {
			if (!this.shareHoldingFolder) {
				this.shareHoldingFolder = { name: 'Folder', filesNew: [] }
			}
			this.shareHoldingFolder.filesNew = this.shareHoldingFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.shareHoldingFolder.filesNew, 'bulk upload', type)
		} else if (type == 'PROVISIONAL_FINANCIAL') {
			if (!this.provisionalFinancialFolder) {
				this.provisionalFinancialFolder = { name: 'Folder', filesNew: [] }
			}
			this.provisionalFinancialFolder.filesNew = this.provisionalFinancialFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.provisionalFinancialFolder.filesNew, 'bulk upload', type)
		} else if (type == 'VINTAGE_TABLE') {
			if (!this.vintageTableFolder) {
				this.vintageTableFolder = { name: 'Folder', filesNew: [] }
			}
			this.vintageTableFolder.filesNew = this.vintageTableFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.vintageTableFolder.filesNew, 'bulk upload', type)
		} else if (type == 'CC_UTILIZATION') {
			if (!this.ccUtilizationFolder) {
				this.ccUtilizationFolder = { name: 'Folder', filesNew: [] }
			}
			this.ccUtilizationFolder.filesNew = this.ccUtilizationFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.ccUtilizationFolder.filesNew, 'bulk upload', type)
		} else if (type == 'DEBTOR_CREDITOR_MONTHLY_MOVEMENT') {
			if (!this.debtorCreditorMovementFolder) {
				this.debtorCreditorMovementFolder = { name: 'Folder', filesNew: [] }
			}
			this.debtorCreditorMovementFolder.filesNew = this.debtorCreditorMovementFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.debtorCreditorMovementFolder.filesNew, 'bulk upload', type)
		} else if (type == 'CMA_PROJECTIONS') {
			if (!this.cmaProjectionsFolder) {
				this.cmaProjectionsFolder = { name: 'Folder', filesNew: [] }
			}
			this.cmaProjectionsFolder.filesNew = this.cmaProjectionsFolder.filesNew.concat(newFiles)
			this.uploadMultipleFiles(this.cmaProjectionsFolder.filesNew, 'bulk upload', type)
		}
	}
	multiFileUploadBankFolder(event: any, type: any, index: any) {
		const newFiles = Array.from(event.target.files) as File[]
		if (!this.bankFolder) {
			this.bankFolder = { name: 'Folder', filesNew: [] }
		}
		this.bankFolder.filesNew = this.bankFolder.filesNew.concat(newFiles)
		if (this.bankFolder.filesNew.length > 10) {
			this.toasterService.error('Only 10 Files Can be Uploaded At A Time!')
			this.bankFolder = null
			return
		}
		let formData = new FormData()
		this.bankFolder.filesNew.forEach((x) => {
			formData.append('files', x)
		})
		let refrenceId = this.bankDetails.at(index).get('id').value ? this.bankDetails.at(index).get('id').value : null
		this.shieldService.uploadBulkFiles(formData, this.laneTaskId, 'bulk upload', type, refrenceId).subscribe({
			next: (resp: any) => {
				this.bankDetails.at(index).get('bankStatements').setValue(resp.result.documentIds)
				this.bankDetails.at(index).get('id').setValue(resp.result.refrenceId)
			},
			complete: () => (this.bankFolder = null),
		})
	}
	resetFiles(){
		this.folder = null
			this.auditFolder = null
			this.salesFolder = null
			this.stockFolder = null
			this.debtorFolder = null
			this.creditorFolder = null
			this.sacntionFolder = null
			this.commercialFolder = null
			this.gstFolder = null
			this.aomoFolder = null
			this.cibilFolder = null
			this.bankFolder = null
			this.borrowerFolder = null;
			this.promoterFolder = null
			this.borrowerCustomerFolder = null
			this.borrowerSupplierFolder = null
			this.ccStatementFolder = null
			this.debtFolder = null
			this.monthlyPaymentFolder = null
			this.saralFolder = null
			this.computationFolder = null
			this.pAndLFolder = null
			this.shareHoldingFolder = null
			this.provisionalFinancialFolder = null
			this.vintageTableFolder = null
			this.ccUtilizationFolder = null
			this.debtorCreditorMovementFolder = null
			this.cmaProjectionsFolder = null
	}
	uploadMultipleFiles(files: any, remark: string, type: any) {
		if (files.length > 10) {
			this.toasterService.error('Only 10 Files Can be Uploaded At A Time!')
			this.resetFiles();
			return
		}
		let formData = new FormData()
		files.forEach((x: any) => {
			formData.append('files', x)
		})
		this.shieldService.uploadBulkFiles(formData, this.laneTaskId, remark, type, null).subscribe({
			next: (resp: any) => {
				if (type == 'AUDITED_STATEMENT') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								auditedStatment: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'SALES_OR_PURCHASE_ORDER') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								salesPurchaseOrder: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'STOCK_STATEMENT') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								stockStatement: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'DEBTOR_STATEMENT') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								debtorStatement: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'CREDITOR_STATEMENT') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								creditorStatement: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'SANCTION_LETTERS') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								sanctionLetters: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'COMMERCIAL_TRANSACTION') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								commTransactionDoc: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'GST_RETURNS') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								gstReturns: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'AOA_AND_MOA') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								aoaAndMoa: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'CIBIL_DATA') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								cibilData: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'BORROWER_ENTITY_NOTE') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								borrowerNote: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'PROMOTER_BACKGROUND_NOTE') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								promoterNote: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'BORROWER_CUSTOMER_LEDGER') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								borrowerCustomerLedger: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'BORROWER_SUPPLIER_LEDGER') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								borrowerSupplierLedger: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'CC_STATEMENT') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								ccStatment: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'DEBT_POSITION') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								debtPosition: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'MONTHLY_REPAYMENT') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								monthlyRepayment: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'SARAL_COPY') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								saralCopy: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'COMPUTATION_INCOME') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								computationIncome: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'PL_BALANCE_SHEET') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								plBalanceSheet: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'SHARE_HOLDING_PATTERN') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								shareHoldingPattern: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'PROVISIONAL_FINANCIAL') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								provisionalFinancial: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'VINTAGE_TABLE') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								vintageTable: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'CC_UTILIZATION') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								ccUtilization: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'DEBTOR_CREDITOR_MONTHLY_MOVEMENT') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								debtorCreditorMonthlyMovement: resp.data.documentIds,
							},
						},
					})
				} else if (type == 'CMA_PROJECTIONS') {
					this.detailForm.patchValue({
						documentationsDTO: {
							companyDocuments: {
								cmaProjections: resp.data.documentIds,
							},
						},
					})
				}
				if(resp.success){
					this.toasterService.success("File Uploaded Successfully!");
				}else{
					this.toasterService.error("Something Went Wrong!");
				}
			},
			error:(err:any)=>{
				this.resetFiles();
				this.toasterService.error("Something Went Wrong Or File Too large!");
			}
			,complete:()=>this.fetchdata(this.laneTaskId)
		})
	}
	showFoldeFileCount(type: any) {
		let show = false
		let documents = (this.detailForm.get('documentationsDTO') as FormGroup).get('companyDocuments')
		if (type == 'AUDITED_STATEMENT') {
			show = documents.get('auditedStatment').value && documents.get('auditedStatment').value.length ? true : false
		} else if (type == 'SALES_OR_PURCHASE_ORDER') {
			show = documents.get('salesPurchaseOrder').value && documents.get('salesPurchaseOrder').value.length ? true : false
		} else if (type == 'STOCK_STATEMENT') {
			show = documents.get('stockStatement').value && documents.get('stockStatement').value.length ? true : false
		} else if (type == 'DEBTOR_STATEMENT') {
			show = documents.get('debtorStatement').value && documents.get('debtorStatement').value.length ? true : false
		} else if (type == 'CREDITOR_STATEMENT') {
			show = documents.get('creditorStatement').value && documents.get('creditorStatement').value.length ? true : false
		} else if (type == 'SANCTION_LETTERS') {
			show = documents.get('sanctionLetters').value && documents.get('sanctionLetters').value.length ? true : false
		} else if (type == 'COMMERCIAL_TRANSACTION') {
			show = documents.get('commTransactionDoc').value && documents.get('commTransactionDoc').value.length ? true : false
		} else if (type == 'GST_RETURNS') {
			show = documents.get('gstReturns').value && documents.get('gstReturns').value.length ? true : false
		} else if (type == 'AOA_AND_MOA') {
			show = documents.get('aoaAndMoa').value && documents.get('aoaAndMoa').value.length ? true : false
		} else if (type == 'CIBIL_DATA') {
			show = documents.get('cibilData').value && documents.get('cibilData').value.length ? true : false
		} else if (type == 'BORROWER_ENTITY_NOTE') {
			show = documents.get('borrowerNote').value && documents.get('borrowerNote').value.length ? true : false
		} else if (type == 'PROMOTER_BACKGROUND_NOTE') {
			show = documents.get('promoterNote').value && documents.get('promoterNote').value.length ? true : false
		} else if (type == 'BORROWER_CUSTOMER_LEDGER') {
			show = documents.get('borrowerCustomerLedger').value && documents.get('borrowerCustomerLedger').value.length ? true : false
		} else if (type == 'BORROWER_SUPPLIER_LEDGER') {
			show = documents.get('borrowerSupplierLedger').value && documents.get('borrowerSupplierLedger').value.length ? true : false
		} else if (type == 'CC_STATEMENT') {
			show = documents.get('ccStatment').value && documents.get('ccStatment').value.length ? true : false
		} else if (type == 'DEBT_POSITION') {
			show = documents.get('debtPosition').value && documents.get('debtPosition').value.length ? true : false
		} else if (type == 'MONTHLY_REPAYMENT') {
			show = documents.get('monthlyRepayment').value && documents.get('monthlyRepayment').value.length ? true : false
		} else if (type == 'SARAL_COPY') {
			show = documents.get('saralCopy').value && documents.get('saralCopy').value.length ? true : false
		} else if (type == 'COMPUTATION_INCOME') {
			show = documents.get('computationIncome').value && documents.get('computationIncome').value.length ? true : false
		} else if (type == 'PL_BALANCE_SHEET') {
			show = documents.get('plBalanceSheet').value && documents.get('plBalanceSheet').value.length ? true : false
		} else if (type == 'SHARE_HOLDING_PATTERN') {
			show = documents.get('shareHoldingPattern').value && documents.get('shareHoldingPattern').value.length ? true : false
		} else if (type == 'PROVISIONAL_FINANCIAL') {
			show = documents.get('provisionalFinancial').value && documents.get('provisionalFinancial').value.length ? true : false
		} else if (type == 'VINTAGE_TABLE') {
			show = documents.get('vintageTable').value && documents.get('vintageTable').value.length ? true : false
		} else if (type == 'CC_UTILIZATION') {
			show = documents.get('ccUtilization').value && documents.get('ccUtilization').value.length ? true : false
		} else if (type == 'DEBTOR_CREDITOR_MONTHLY_MOVEMENT') {
			show = documents.get('debtorCreditorMonthlyMovement').value && documents.get('debtorCreditorMonthlyMovement').value.length ? true : false
		} else if (type == 'CMA_PROJECTIONS') {
			show = documents.get('cmaProjections').value && documents.get('cmaProjections').value.length ? true : false
		}
		return show
	}

	showFolderFileCountBank(index: number) {
		let show = false
		show = this.bankDetails.at(index).get('bankStatements').value && this.bankDetails.at(index).get('bankStatements').value.length ? true : false
		return show
	}

	businessDetailKyc(control: any, type: any) {
		if (type == 'kyc') return control.controls.kycDocument.value
		else return control.controls.panCard.value
	}
	getFileCount(type: any) {
		let show = 0
		let documents = (this.detailForm.get('documentationsDTO') as FormGroup).get('companyDocuments')
		if (type == 'AUDITED_STATEMENT') {
			show = documents.get('auditedStatment').value.length
		} else if (type == 'SALES_OR_PURCHASE_ORDER') {
			show = documents.get('salesPurchaseOrder').value.length
		} else if (type == 'STOCK_STATEMENT') {
			show = documents.get('stockStatement').value.length
		} else if (type == 'DEBTOR_STATEMENT') {
			show = documents.get('debtorStatement').value.length
		} else if (type == 'CREDITOR_STATEMENT') {
			show = documents.get('creditorStatement').value.length
		} else if (type == 'SANCTION_LETTERS') {
			show = documents.get('sanctionLetters').value.length
		} else if (type == 'COMMERCIAL_TRANSACTION') {
			show = documents.get('commTransactionDoc').value.length
		} else if (type == 'GST_RETURNS') {
			show = documents.get('gstReturns').value.length
		} else if (type == 'AOA_AND_MOA') {
			show = documents.get('aoaAndMoa').value.length
		} else if (type == 'CIBIL_DATA') {
			show = documents.get('cibilData').value.length
		} else if (type == 'BORROWER_ENTITY_NOTE') {
			show = documents.get('borrowerNote').value.length
		} else if (type == 'PROMOTER_BACKGROUND_NOTE') {
			show = documents.get('promoterNote').value.length
		} else if (type == 'BORROWER_CUSTOMER_LEDGER') {
			show = documents.get('borrowerCustomerLedger').value.length
		} else if (type == 'BORROWER_SUPPLIER_LEDGER') {
			show = documents.get('borrowerSupplierLedger').value.length
		} else if (type == 'CC_STATEMENT') {
			show = documents.get('ccStatment').value.length
		} else if (type == 'DEBT_POSITION') {
			show = documents.get('debtPosition').value.length
		} else if (type == 'MONTHLY_REPAYMENT') {
			show = documents.get('monthlyRepayment').value.length
		} else if (type == 'SARAL_COPY') {
			show = documents.get('saralCopy').value.length
		} else if (type == 'COMPUTATION_INCOME') {
			show = documents.get('computationIncome').value.length
		} else if (type == 'PL_BALANCE_SHEET') {
			show = documents.get('plBalanceSheet').value.length
		} else if (type == 'SHARE_HOLDING_PATTERN') {
			show = documents.get('shareHoldingPattern').value.length
		} else if (type == 'PROVISIONAL_FINANCIAL') {
			show = documents.get('provisionalFinancial').value.length
		} else if (type == 'VINTAGE_TABLE') {
			show = documents.get('vintageTable').value.length
		} else if (type == 'CC_UTILIZATION') {
			show = documents.get('ccUtilization').value.length
		} else if (type == 'DEBTOR_CREDITOR_MONTHLY_MOVEMENT') {
			show = documents.get('debtorCreditorMonthlyMovement').value.length
		} else if (type == 'CMA_PROJECTIONS') {
			show = documents.get('cmaProjections').value.length
		}
		return show
	}
	getFileCountBank(index: number) {
		return this.bankDetails.at(index).get('bankStatements').value.length
	}
	getTabValue(event: any) {
		if (event == 0) {
			this.tabName = 'company details'
			this.clearValidatorsBusniess()
		} else if (event == 1) {
			this.tabName = 'bussiness user'
			if (this.businessUserDetails.length > 0) {
				this.businessUserDetails.controls.forEach((element: any) => {
					Object.keys(element.controls).forEach((controlname) => {
						let control = element.get(controlname)
						if (['name', 'dob', 'gender', 'citizenship', 'contactNo', 'address', 'city', 'state', 'panCard', 'kycDocument'].includes(controlname)) {
							control.setValidators(Validators.required)
						}
						if (controlname === 'sharePercentage' && !this.propritorCheck) {
							control.setValidators(Validators.required)
						}
						if (controlname === 'email') {
							control.setValidators([Validators.required, Validators.email])
						}
						if (controlname === 'pincode') {
							control.setValidators([Validators.required, Validators.minLength(6), Validators.maxLength(6)])
						}
						control.updateValueAndValidity()
					})
				})
				this.businessUserDetails.updateValueAndValidity()
			}
		} else if (event == 2) {
			this.tabName = 'documentation'
			this.clearValidatorsBusniess()
		}
	}

	clearValidatorsBusniess() {
		if (this.businessUserDetails.length > 0) {
			this.businessUserDetails.controls.forEach((element: any) => {
				Object.keys(element.controls).forEach((controlname) => {
					let control = element.get(controlname)
					control.clearValidators()
					control.updateValueAndValidity()
				})
			})
			this.businessUserDetails.updateValueAndValidity()
		}
	}

	fetchdata(x: any) {
		if (x !== null && x !== 'undefined') {
			this.shieldService.fetchData(x).subscribe({
				next: (resp: any) => {
					this.shieldService.setLaneData(resp.data)
					this.editable(resp)
					this.folder = null
					this.auditFolder = null
					this.salesFolder = null
					this.stockFolder = null
					this.debtorFolder = null
					this.creditorFolder = null
					this.bankFolder = null
					this.sacntionFolder = null
					this.commercialFolder = null
					this.gstFolder = null
					this.aomoFolder = null
					this.cibilFolder = null
					this.bankFolder = null
					this.borrowerFolder = null
					this.promoterFolder = null
					this.borrowerCustomerFolder = null
					this.borrowerSupplierFolder = null
					this.ccStatementFolder = null
					this.debtFolder = null
					this.monthlyPaymentFolder = null
					this.saralFolder = null
					this.computationFolder = null
					this.pAndLFolder = null
					this.shareHoldingFolder = null
					this.provisionalFinancialFolder = null
					this.vintageTableFolder = null
					this.ccUtilizationFolder = null
					this.debtorCreditorMovementFolder = null
					this.cmaProjectionsFolder = null
					// this.isEligibleToOnboard = resp.result.isEligibleToOnboard
					if (resp.data.applicationPreview && resp.data.applicationPreview.leadDetails) {
						this.newLeadLaneData = resp.data.applicationPreview.leadDetails
						this.laneData = resp.data.applicationPreview.detailsAndDocumentation
						this.detailForm.patchValue({
							companyDetails: {
								supplierName: resp.data.applicationPreview.leadDetails.supplierName,
								typeOfCompany: resp.data.applicationPreview.leadDetails.typeOfCompany,
								natureOfBusiness: resp.data.applicationPreview.leadDetails.natureOfBusiness,
								companyPAN: resp.data.applicationPreview.leadDetails.companyPan,
								gstinNo: resp.data.applicationPreview.leadDetails.gstinNo,
							},
						})
						this.sidPidName = resp.data.applicationPreview.leadDetails.businessProduct == 'SID' ? 'Sales' : 'Purchase'
						let checkValue = resp.data.applicationPreview.leadDetails.typeOfCompany
						if (checkValue == 'PROPRIETORSHIP' || checkValue == 'PARTNERSHIP' || checkValue == 'LLP') {
							this.aoaAndIncomeCheck = false
							this.propritorCheck = checkValue == 'PROPRIETORSHIP' ? true : false;
						} else {
							this.aoaAndIncomeCheck = true
						}
						if (checkValue == 'PRIVATE' || checkValue == 'PUBLIC' || checkValue == 'LLP') {
							this.shareName = 'Share Holding Pattern';
							(this.detailForm.get('companyDetails') as FormGroup)
								.get('cinNo')
								.setValidators([Validators.required, Validators.minLength(21), Validators.maxLength(21), Validators.pattern(/^[A-Za-z0-9]*$/)]);
							(this.detailForm.get('companyDetails') as FormGroup).get('cinNoFile').setValidators([Validators.required])
							this.showCin = true
							this.detailForm.updateValueAndValidity()
						}else if(checkValue == 'PARTNERSHIP'){
							this.shareName = 'Profit Sharing'
						}
						this.addCompanyPan(resp.data.applicationPreview.leadDetails.typeOfCompany)
						let business = resp.data.applicationPreview.leadDetails.businessProduct
						this.showQuestion = business == 'PID' ? true : false;
						this.showMultiUpload = business == 'SID' ? true : false;
						if (business == 'SID' || business == 'DEALER_FINANCE') {
							this.shieldService.setBuyerValue('Buyer')
						} else {
							this.shieldService.setBuyerValue('Supplier')
						}
					}
					if (resp.data.applicationPreview.detailsAndDocumentation && resp.data.applicationPreview.detailsAndDocumentation.companyDetails) {
						this.detailForm.patchValue({
							companyDetails: {
								cinNo: resp.data.applicationPreview.detailsAndDocumentation.companyDetails.cinNo,
								cinNoFile: resp.data.applicationPreview.detailsAndDocumentation.companyDetails.cinNoFile,
								companyPANFile: resp.data.applicationPreview.detailsAndDocumentation.companyDetails.companyPANFile,
								gstinNoFile: resp.data.applicationPreview.detailsAndDocumentation.companyDetails.gstinNoFile,
							},
						})
						let data = this.detailForm.get('companyDetails') as FormGroup
						data.get('supplierName').setValue(resp.data.applicationPreview.leadDetails.supplierName)
						data.get('typeOfCompany').setValue(resp.data.applicationPreview.leadDetails.typeOfCompany)
						data.get('natureOfBusiness').setValue(resp.data.applicationPreview.leadDetails.natureOfBusiness)
						;((this.detailForm.get('documentationsDTO') as FormGroup).get('businessVintageDTO') as FormGroup).get('typeOfCompany').setValue(resp.data.applicationPreview.leadDetails.typeOfCompany)
					}
					if (
						resp.data.applicationPreview.detailsAndDocumentation &&
						resp.data.applicationPreview.detailsAndDocumentation.businessUserDetails &&
						resp.data.applicationPreview.detailsAndDocumentation.businessUserDetails.length > 0
					) {
						this.createBusinessUserDetails(resp.data.applicationPreview.detailsAndDocumentation.businessUserDetails)
					} else {
						while (this.businessUserDetails.length) {
							this.businessUserDetails.removeAt(0)
						}
						// this.businessUserDetails.push(this.createBusinessUserDetails())
					}
					if (
						resp.data.applicationPreview.detailsAndDocumentation &&
						resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO &&
						resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.bankDetails &&
						resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.bankDetails.length > 0
					) {
						this.createBankDetails(resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.bankDetails)
					} else {
						while (this.bankDetails.length) {
							this.bankDetails.removeAt(0)
						}
						this.bankDetails.push(this.createBankDetails())
					}
					if (resp.data.applicationPreview && resp.data.applicationPreview.detailsAndDocumentation && resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO) {
						this.detailForm.patchValue({
							documentationsDTO: {
								businessVintageDTO: {
									gstCertificate:
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.businessVintageDTO &&
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.businessVintageDTO.gstCertificate
											? resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.businessVintageDTO.gstCertificate
											: null,
									gstCertificateFile:
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.businessVintageDTO &&
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.businessVintageDTO.gstCertificateFile
											? resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.businessVintageDTO.gstCertificateFile
											: null,
									udhyamAadharCertificate:
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.businessVintageDTO &&
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.businessVintageDTO.udhyamAadharCertificate
											? resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.businessVintageDTO.udhyamAadharCertificate
											: null,
									udhyamAadharCertificateFile:
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.businessVintageDTO &&
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.businessVintageDTO.udhyamAadharCertificateFile
											? resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.businessVintageDTO.udhyamAadharCertificateFile
											: null,
									incorporationCertificateFile:
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.businessVintageDTO &&
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.businessVintageDTO.incorporationCertificateFile
											? resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.businessVintageDTO.incorporationCertificateFile
											: null,
								},
								residenceDocuments: {
									waterBillFile:
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.residenceDocuments &&
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.residenceDocuments.waterBillFile
											? resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.residenceDocuments.waterBillFile
											: null,
									electricBillFile:
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.residenceDocuments &&
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.residenceDocuments.electricBillFile
											? resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.residenceDocuments.electricBillFile
											: null,
									ownerShipProofDoc:
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.residenceDocuments &&
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.residenceDocuments.ownerShipProofDoc
											? resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.residenceDocuments.ownerShipProofDoc
											: null,
								},
								creditQuestionaire: {
									isBaseLocationBeyond100km:
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire &&
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire.isBaseLocationBeyond100km
											? resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire.isBaseLocationBeyond100km
											: 'NA',
									isRatingBelowBBplus:
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire &&
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire.isRatingBelowBBplus
											? resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire.isRatingBelowBBplus
											: 'NA',
									isTradingEntity:
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire &&
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire.isTradingEntity
											? resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire.isTradingEntity
											: 'NA',
									isRelyingOnGovtEntitiesForSales:
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire &&
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire.isRelyingOnGovtEntitiesForSales
											? resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire.isRelyingOnGovtEntitiesForSales
											: 'NA',
									hasActiveOperationsLessThan36Months:
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire &&
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire.hasActiveOperationsLessThan36Months
											? resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire.hasActiveOperationsLessThan36Months
											: 'NA',
									hasNetworthLessThan2_50Cr:
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire &&
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire.hasNetworthLessThan2_50Cr
											? resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire.hasNetworthLessThan2_50Cr
											: 'NA',
									hasOperatingScaleLessThan15Cr:
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire &&
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire.hasOperatingScaleLessThan15Cr
											? resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire.hasOperatingScaleLessThan15Cr
											: 'NA',
									hasNegativeEBITDAInLast2FY:
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire &&
										resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire.hasNegativeEBITDAInLast2FY
											? resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.creditQuestionaire.hasNegativeEBITDAInLast2FY
											: 'NA',
								},
								companyDocuments: resp.data.applicationPreview.detailsAndDocumentation.documentationsDTO.companyDocuments,
							},
						})
					}
				},
				error: (err: any) => {
					this.bankDetails.push(this.createBankDetails())
				},
			})
		}
	}
	editable(resp: any) {
		//check if editable
		// this.shieldService.hasAccess.subscribe((access) => {
		// 	this.shieldService.laneListIds.subscribe((x) => {
		// 		this.laneIdList= x;
		// 		if (x && Object.keys(x).length > 0) {
		// 			let obj = x.filter((element:any) => element.laneName == 'application-documents-submitted')
		// 			if (resp.result.laneId == obj[0].laneId && access) {
		// 				this.showSave = true
		// 				this.detailForm.enable()
		// 				this.fieldEnable = true
		// 			} else {
		// 				this.showSave = false
		// 				this.detailForm.disable()
		// 				this.fieldEnable = false
		// 				this.isEligibleToOnboard = false;
		// 				this.nextLane = true;
		// 			}
		// 		}
		;(this.detailForm.get('companyDetails') as FormGroup).get('supplierName').disable()
		;(this.detailForm.get('companyDetails') as FormGroup).get('typeOfCompany').disable()
		;(this.detailForm.get('companyDetails') as FormGroup).get('natureOfBusiness').disable()
		;(this.detailForm.get('companyDetails') as FormGroup).get('companyPAN').disable()
		;(this.detailForm.get('companyDetails') as FormGroup).get('gstinNo').disable()
		for (let i = 0; i < this.businessUserDetails.length; i++) {
			this.businessUserDetails.at(i).get('typeOfCompany').disable()
		}
		// 	})
		// })
		// this.showConsent();
	}
	fetchByCin() {
		let cinNo = (this.detailForm.get('companyDetails') as FormGroup).get('cinNo').value
		if (cinNo) {
			this.shieldService.fetchUsingCin(cinNo).subscribe({
				next: (resp: any) => {
					if (resp.success) {
						this.toasterService.success('Details collected successfully. Kindly include more details about the directors.')
						this.cinUserFilteration(resp.result)
					} else {
						this.toasterService.error('Failed to obtain details. Kindly add more information about the directors.')
					}
				},
				error: (err: any) => this.toasterService.error('Failed to obtain details. Kindly add more information about the directors.'),
			})
		} else {
			this.toasterService.error('Please Enter CIN No Before Fetching!')
		}
	}
	cinUserFilteration(details: any) {
		let data = details.concat(this.businessUserDetails.value)
		let requiredMap: Map<string, any> = new Map()
		data.forEach((element: any) => {
			if (!requiredMap.has(element.name)) {
				requiredMap.set(element.name, element)
			}
		})
		let businessUsers = Array.from(requiredMap.values())
		if (businessUsers.length > 0) {
			this.selectedTabIndex = 1
		}
		this.createBusinessUserDetails(businessUsers)
	}
	addCompanyPan(companyType: string) {
		if (companyType == 'PROPRIETORSHIP') {
			this.showCompanyPan = 'Individual'
		} else {
			this.showCompanyPan = 'Company'
		}
	}

	patchCompanyDocuments(companyDocs: any) {
		if (companyDocs) {
			Object.keys(companyDocs).forEach((key) => {
				this.detailForm.patchValue({
					documentationsDTO: {
						companyDocuments: {
							key: companyDocs[key],
						},
					},
				})
				console.log(this.detailForm.getRawValue())
			})
		}
	}

	createBusinessUserDetails(details: any[] = null) {
		if (details && details.length > 0) {
			while (this.businessUserDetails.length) {
				this.businessUserDetails.removeAt(0)
			}
			// this.detailForm.patchValue({businessUserDetails : this.fb.array([])});
			details.forEach((detail: any) => {
				let businessUserDetail
				if (this.propritorCheck) {
					businessUserDetail = this.fb.group({
						typeOfCompany: [{ value: this.newLeadLaneData.typeOfCompany, disabled: true }],
						name: [detail.name],
						dob: [{value: detail.dob, disabled: true}],
						gender: [detail.gender],
						citizenship: [detail.citizenship],
						contactNo: [detail.contactNo],
						email: [detail.email],
						dinNo: [detail.dinNo],
						sharePercentage:[detail.sharePercentage],
						address: [detail.address],
						addressTwo: [detail.addressTwo],
						city: [detail.city],
						state: [detail.state],
						pincode: [detail.pincode],
						panCard: [{value: detail.panCard, disabled: true}],
						panCardFile: [detail.panCardFile],
						kycDocument: [{value: detail.kycDocument, disabled:true}],
						kycDocumentFile: [detail.kycDocumentFile],
						id: [detail.id],
						wheatherDscRegistered: [detail.wheatherDscRegistered ? detail.wheatherDscRegistered : 'NO'],
					})
				} else {
					businessUserDetail = this.fb.group({
						typeOfCompany: [{ value: this.newLeadLaneData.typeOfCompany, disabled: true }],
						name: [detail.name],
						dob: [{value: detail.dob, disabled: true}],
						gender: [detail.gender],
						citizenship: [detail.citizenship],
						contactNo: [detail.contactNo],
						email: [detail.email],
						dinNo: [detail.dinNo],
						sharePercentage:[detail.sharePercentage],
						address: [detail.address],
						addressTwo: [detail.addressTwo],
						city: [detail.city],
						state: [detail.state],
						pincode: [detail.pincode],
						panCard: [{value: detail.panCard, disabled: true}],
						panCardFile: [detail.panCardFile],
						kycDocument: [{value: detail.kycDocument, disabled:true}],
						kycDocumentFile: [detail.kycDocumentFile],
						id: [detail.id],
						wheatherDscRegistered: [detail.wheatherDscRegistered ? detail.wheatherDscRegistered : 'NO'],
					})
				}
				this.businessUserDetails.push(businessUserDetail)
			})
		}
		if (this.propritorCheck) {
			return this.fb.group({
				typeOfCompany: [{ value: this.newLeadLaneData.typeOfCompany, disabled: true }],
				name: [null, Validators.required],
				dob: [null, Validators.required],
				gender: [null, Validators.required],
				citizenship: [null, Validators.required],
				contactNo: [null, Validators.required],
				email: [null, [Validators.required, Validators.email]],
				dinNo: [null],
				sharePercentage:[],
				address: [null, Validators.required],
				addressTwo: [],
				city: [null, Validators.required],
				state: [null, Validators.required],
				pincode: [null, [Validators.minLength(6), Validators.maxLength(6), Validators.required]],
				panCard: [null, Validators.required],
				panCardFile: [],
				kycDocument: [null, Validators.required],
				kycDocumentFile: [],
				id: [null],
				wheatherDscRegistered: ['NO'],
			})
		} else {
			return this.fb.group({
				typeOfCompany: [{ value: this.newLeadLaneData.typeOfCompany, disabled: true }],
				// typeOfCompany: [null,Validators.required],
				name: [null, Validators.required],
				dob: [null, Validators.required],
				gender: [null, Validators.required],
				citizenship: [null, Validators.required],
				contactNo: [null, Validators.required],
				email: [null, [Validators.required, Validators.email]],
				dinNo: [null],
				sharePercentage:[null,Validators.required],
				address: [null, Validators.required],
				addressTwo: [],
				city: [null, Validators.required],
				state: [null, Validators.required],
				pincode: [null, [Validators.minLength(6), Validators.maxLength(6), Validators.required]],
				panCard: [null, Validators.required],
				panCardFile: [],
				kycDocument: [null, Validators.required],
				kycDocumentFile: [],
				id: [null],
				wheatherDscRegistered: ['NO'],
			})
		}
	}

	createBankDetails(details: [] = null) {
		if (details && details.length > 0) {
			while (this.bankDetails.length) {
				this.bankDetails.removeAt(0)
			}
			details.forEach((details: any) => {
				const bankDetails = this.fb.group({
					id: [details.id],
					bankAccountNumber: [details.bankAccountNumber],
					ifscCode: [details.ifscCode],
					accountType: [details.accountType],
					bankAccountName: [details.bankAccountName],
					bankStatements: [details.bankStatements],
				})
				this.bankDetails.push(bankDetails)
			})
		}
		return this.fb.group({
			id: [null],
			bankAccountNumber: [null],
			ifscCode: [null],
			accountType: [null],
			bankAccountName: [null],
			bankStatements: [[]],
		})
	}
	addBank(index: number) {
		let bankData = this.bankDetails.at(index).value
		if (bankData.bankAccountNumber && bankData.ifscCode && bankData.accountType && bankData.bankAccountName) {
			this.bankDetails.push(this.createBankDetails())
		} else {
			this.toasterService.error('Please Fill Bank Details Before Adding New Bank!')
		}
	}

	removeBank(index: number) {
		this.bankDetails.removeAt(index)
		if (index == 0) {
			this.bankDetails.push(this.createBankDetails())
		}
	}

	restrictPhoneLength(event: any): void {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[0-9]$/
		const inputChar = String.fromCharCode(event.keyCode)
		const isValidChar = allowedPattern.test(inputChar)
		if (!isValidChar || input.value.length >= 10) {
			event.preventDefault()
		}
	}
	restrictPinCode(event: any) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[0-9]$/
		const inputChar = String.fromCharCode(event.keyCode)
		const isValidChar = allowedPattern.test(inputChar)
		if (!isValidChar || input.value.length >= 6) {
			event.preventDefault()
		}
	}
	restrictAccountNo(event: any) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[0-9]$/
		const inputChar = String.fromCharCode(event.keyCode)
		const isValidChar = allowedPattern.test(inputChar)
		if (!isValidChar || input.value.length >= 16) {
			event.preventDefault()
		}
	}
	restrictPan(event: any) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[a-zA-Z0-9]$/
		const inputChar = String.fromCharCode(event.keyCode)
		const isValidChar = allowedPattern.test(inputChar)
		if (!isValidChar || input.value.length >= 10) {
			event.preventDefault()
		}
	}
	restrictIfsc(event: any) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[a-zA-Z0-9]$/
		const inputChar = String.fromCharCode(event.keyCode)
		const isValidChar = allowedPattern.test(inputChar)
		if (!isValidChar || input.value.length >= 11) {
			event.preventDefault()
		}
	}
	restrictCin(event: any) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[a-zA-Z0-9]$/
		const inputChar = String.fromCharCode(event.keyCode)
		const isValidChar = allowedPattern.test(inputChar)
		if (!isValidChar || input.value.length >= 21) {
			event.preventDefault()
		}
	}
	restrictGstin(event: any) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[a-zA-Z0-9]$/
		const inputChar = String.fromCharCode(event.keyCode)
		const isValidChar = allowedPattern.test(inputChar)
		if (!isValidChar || input.value.length >= 15) {
			event.preventDefault()
		}
	}
	restrictDin(event: any) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[0-9]$/
		const inputChar = String.fromCharCode(event.keyCode)
		const isValidChar = allowedPattern.test(inputChar)
		if (!isValidChar || input.value.length >= 8) {
			event.preventDefault()
		}
	}

	restrictShare(event:any){
		const input = event.target as HTMLInputElement;
		const allowedPattern = /^[0-9]$/;
		const inputChar = String.fromCharCode(event.keyCode || event.which);
		// Check if the input character is valid (a number)
		const isValidChar = allowedPattern.test(inputChar);
		// Get the current input value as a number
		const currentValue = parseInt(input.value + inputChar);
		// Prevent input if it's not a valid character or exceeds 100
		if (!isValidChar || currentValue > 100 || input.value.length >= 3) {
		  event.preventDefault();
		}
	}

	restrictName(event: any) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[a-zA-Z\s]*$/
		const inputChar = String.fromCharCode(event.keyCode)
		if (!allowedPattern.test(inputChar)) {
			event.preventDefault()
		}
		if (input.value.endsWith(' ') && inputChar === ' ') {
			event.preventDefault()
		}
	}
	restrictAadhar(event: any) {
		const input = event.target as HTMLInputElement
		const allowedPattern = /^[0-9]$/
		const inputChar = String.fromCharCode(event.keyCode)
		const isValidChar = allowedPattern.test(inputChar)
		if (!isValidChar || input.value.length >= 12) {
			event.preventDefault()
		}
	}
	restrictAadharLength(event: any) {
		if (event.target.value.length > 18) {
			return false
		}
	}
	removeLastSpace(event: any) {
		const input = event.target as HTMLInputElement
		input.value = input.value.trim()
	}
	get businessUserDetails() {
		return this.detailForm.get('businessUserDetails') as FormArray
	}
	get bankDetails() {
		return (this.detailForm.get('documentationsDTO') as FormGroup).get('bankDetails') as FormArray
	}

	companyPanReplicate() {
		let companyDetail = this.detailForm.get('companyDetails') as FormGroup
		// let documentationsDTO = (this.detailForm.get('documentationsDTO') as FormGroup).get('businessVintageDTO');
		companyDetail.get('companyPAN').valueChanges.subscribe((x) => {
			this.detailForm.patchValue({
				documentationsDTO: {
					businessVintageDTO: {
						companyPanCard: x,
						directorKYC: x,
					},
				},
			})
		})
	}

	typeOfcompanyReplicate() {
		let companyDetail = this.detailForm.get('companyDetails') as FormGroup
		// let documentationsDTO = (this.detailForm.get('documentationsDTO') as FormGroup).get('businessVintageDTO');
		companyDetail.get('typeOfCompany').valueChanges.subscribe((x) => {
			this.detailForm.patchValue({
				documentationsDTO: {
					businessVintageDTO: {
						typeOfCompany: x,
					},
				},
			})
		})
	}
	fileUpload(event: any, fileType: any) {
		this.isProgressVisible = true
		let details = this.detailForm.get('companyDetails')
		let documents = (this.detailForm.get('documentationsDTO') as FormGroup).get('businessVintageDTO')
		let residenceDocuments = (this.detailForm.get('documentationsDTO') as FormGroup).get('residenceDocuments')
		if (fileType == 'COMPANY_PAN') {
			// }
			if (event[0]) {
				this.uploadSingleFile(event[0], details.get('companyPAN').value, fileType, details.get('companyPANFile').value ? details.get('companyPANFile').value : null)
			} else {
				this.uploadSingleFile(event.target.files[0], details.get('companyPAN').value, fileType, details.get('companyPANFile').value ? details.get('companyPANFile').value : null)
			}
		} else if (fileType == 'CIN_NUMBER') {
			if (event[0]) {
				this.uploadSingleFile(event[0], details.get('cinNo').value, fileType, details.get('cinNoFile').value ? details.get('cinNoFile').value : null)
			} else {
				this.uploadSingleFile(event.target.files[0], details.get('cinNo').value, fileType, details.get('cinNoFile').value ? details.get('cinNoFile').value : null)
			}
		} else if (fileType == 'GSTIN_NUMBER') {
			if (event[0]) {
				this.uploadSingleFile(event[0], details.get('gstinNo').value, fileType, details.get('gstinNoFile').value ? details.get('gstinNoFile').value : null)
			} else {
				this.uploadSingleFile(event.target.files[0], details.get('gstinNo').value, fileType, details.get('gstinNoFile').value ? details.get('gstinNoFile').value : null)
			}
		} else if (fileType == 'GST_REGISTRATION') {
			if (event[0]) {
				this.uploadSingleFile(event[0], documents.get('gstCertificate').value, fileType, documents.get('gstCertificateFile').value ? documents.get('gstCertificateFile').value : null)
			} else {
				this.uploadSingleFile(
					event.target.files[0],
					documents.get('gstCertificate').value,
					fileType,
					documents.get('gstCertificateFile').value ? documents.get('gstCertificateFile').value : null,
				)
			}
		} else if (fileType == 'UDHYAM_AADHAR') {
			if (event[0]) {
				this.uploadSingleFile(
					event[0],
					documents.get('udhyamAadharCertificate').value,
					fileType,
					documents.get('udhyamAadharCertificateFile').value ? documents.get('udhyamAadharCertificateFile').value : null,
				)
			} else {
				this.uploadSingleFile(
					event.target.files[0],
					documents.get('udhyamAadharCertificate').value,
					fileType,
					documents.get('udhyamAadharCertificateFile').value ? documents.get('udhyamAadharCertificateFile').value : null,
				)
			}
		} else if (fileType == 'INCORPORATION_CERTIFICATE') {
			if (event[0]) {
				this.uploadSingleFile(event[0], '', fileType, documents.get('incorporationCertificateFile').value ? documents.get('incorporationCertificateFile').value : null)
			} else {
				this.uploadSingleFile(event.target.files[0], '', fileType, documents.get('incorporationCertificateFile').value ? documents.get('incorporationCertificateFile').value : null)
			}
		} else if (fileType == 'WATER_BILL') {
			if (event[0]) {
				this.uploadSingleFile(event[0], '', fileType, residenceDocuments.get('waterBillFile').value ? residenceDocuments.get('waterBillFile').value : null)
			} else {
				this.uploadSingleFile(event.target.files[0], '', fileType, residenceDocuments.get('waterBillFile').value ? residenceDocuments.get('waterBillFile').value : null)
			}
		} else if (fileType == 'ELECTRICITY_BILL') {
			if (event[0]) {
				this.uploadSingleFile(event[0], '', fileType, residenceDocuments.get('electricBillFile').value ? residenceDocuments.get('electricBillFile').value : null)
			} else {
				this.uploadSingleFile(event.target.files[0], '', fileType, residenceDocuments.get('electricBillFile').value ? residenceDocuments.get('electricBillFile').value : null)
			}
		} else if (fileType == 'OWNERSHIP_PROOF') {
			if (event[0]) {
				this.uploadSingleFile(event[0], '', fileType, residenceDocuments.get('ownerShipProofDoc').value ? residenceDocuments.get('ownerShipProofDoc').value : null)
			} else {
				this.uploadSingleFile(event.target.files[0], '', fileType, residenceDocuments.get('ownerShipProofDoc').value ? residenceDocuments.get('ownerShipProofDoc').value : null)
			}
		}
	}
	addDirector(index: number) {
		if (index > 8) {
			this.toasterService.error('Maximum Directors Added!')
			return
		}
		if (index == -1) {
			this.businessUserDetails.push(this.createBusinessUserDetails(null))
			return
		}
		let itemArr = (this.businessUserDetails.at(index) as FormGroup).getRawValue();
		if (
			itemArr.name &&
			itemArr.dob &&
			itemArr.gender &&
			itemArr.citizenship &&
			itemArr.contactNo &&
			itemArr.email &&
			itemArr.address &&
			itemArr.city &&
			itemArr.state &&
			itemArr.pincode &&
			itemArr.panCard &&
			itemArr.panCardFile &&
			itemArr.kycDocument &&
			itemArr.kycDocumentFile &&
			itemArr.sharePercentage
		) {
			this.businessUserDetails.push(this.createBusinessUserDetails(null))
			this.selectedDirector = this.businessUserDetails.length - 1
		} else {
			this.toasterService.error('Please Add Details Before Adding New Director!')
		}
	}
	deleteDirector(index: number) {
		this.businessUserDetails.removeAt(index)
	}
	uploadSingleFile(file: Blob, value: any, type?: any, oldDocId?: any) {
		this.shieldService.uploadSingleFile(this.laneTaskId, type, value, file, oldDocId, null).subscribe({
			next: (resp: any) => {
				if (resp.success == false) {
					this.toasterService.error(resp.message)
					return
				}
				if (type == 'COMPANY_PAN') {
					this.detailForm.patchValue({
						companyDetails: {
							companyPANFile: resp.data.id,
						},
					})
					this.detailForm.patchValue({
						documentationsDTO: {
							businessVintageDTO: {
								companyPanCardFile: resp.data.id,
								directorKYCfile: resp.data.id,
							},
						},
					})
				} else if (type == 'CIN_NUMBER') {
					this.detailForm.patchValue({
						companyDetails: {
							cinNoFile: resp.data.id,
						},
					})
				} else if (type == 'GSTIN_NUMBER') {
					this.detailForm.patchValue({
						companyDetails: {
							gstinNoFile: resp.data.id,
						},
					})
				} else if (type == 'GST_REGISTRATION') {
					this.detailForm.patchValue({
						documentationsDTO: {
							businessVintageDTO: {
								gstCertificateFile: resp.data.id,
							},
						},
					})
				} else if (type == 'UDHYAM_AADHAR') {
					this.detailForm.patchValue({
						documentationsDTO: {
							businessVintageDTO: {
								udhyamAadharCertificateFile: resp.data.id,
							},
						},
					})
				} else if (type == 'INCORPORATION_CERTIFICATE') {
					this.detailForm.patchValue({
						documentationsDTO: {
							businessVintageDTO: {
								incorporationCertificateFile: resp.data.id,
							},
						},
					})
				} else if (type == 'WATER_BILL') {
					this.detailForm.patchValue({
						documentationsDTO: {
							residenceDocuments: {
								waterBillFile: resp.data.id,
							},
						},
					})
				} else if (type == 'ELECTRICITY_BILL') {
					this.detailForm.patchValue({
						documentationsDTO: {
							residenceDocuments: {
								electricBillFile: resp.data.id,
							},
						},
					})
				} else if (type == 'OWNERSHIP_PROOF') {
					this.detailForm.patchValue({
						documentationsDTO: {
							residenceDocuments: {
								ownerShipProofDoc: resp.data.id,
							},
						},
					})
				}
			},
		})
	}
	showCompanyDetailsDoc(type: string) {
		let details = this.detailForm.get('companyDetails')
		let documents = (this.detailForm.get('documentationsDTO') as FormGroup).get('businessVintageDTO')
		let residenceDocuments = (this.detailForm.get('documentationsDTO') as FormGroup).get('residenceDocuments')
		if (type == 'COMPANY_PAN') {
			return details.get('companyPANFile').value ? true : false
		} else if (type == 'CIN_NUMBER') {
			return details.get('cinNoFile').value ? true : false
		} else if (type == 'GSTIN_NUMBER') {
			return details.get('gstinNoFile').value ? true : false
		}
		//  else if (type == 'UTILITY_BILLS') {
		// 	return documents.get('utilityBills').value ? true : false
		// }
		else if (type == 'GST_REGISTRATION') {
			return documents.get('gstCertificateFile').value ? true : false
		} else if (type == 'UDHYAM_AADHAR') {
			return documents.get('udhyamAadharCertificateFile').value ? true : false
		} else if (type == 'INCORPORATION_CERTIFICATE') {
			return documents.get('incorporationCertificateFile').value ? true : false
		} else if (type == 'WATER_BILL') {
			return residenceDocuments.get('waterBillFile').value ? true : false
		} else if (type == 'ELECTRICITY_BILL') {
			return residenceDocuments.get('electricBillFile').value ? true : false
		} else if (type == 'OWNERSHIP_PROOF') {
			return residenceDocuments.get('ownerShipProofDoc').value ? true : false
		}
	}
	showUpload(type: any, index: number) {
		if (type == 'pan') {
			return this.businessUserDetails.at(index).get('panCardFile').value ? true : false
		} else {
			return this.businessUserDetails.at(index).get('kycDocumentFile').value ? true : false
		}
	}
	fileUploadInBusinessDetails(event: any, fileType: any, index: number) {
		this.isProgressVisible = true
		let businessDetails = this.businessUserDetails.at(index)
		if (fileType == 'PAN_CARD') {
			// }
			if (event[0]) {
				this.uploadBusinessSingleFile(
					event[0],
					businessDetails.get('panCard').value,
					fileType,
					this.businessUserDetails.at(index).get('id').value ? this.businessUserDetails.at(index).get('id').value : null,
					index,
					businessDetails.get('panCardFile').value ? businessDetails.get('panCardFile').value : null,
				)
			} else {
				this.uploadBusinessSingleFile(
					event.target.files[0],
					businessDetails.get('panCard').value,
					fileType,
					this.businessUserDetails.at(index).get('id').value ? this.businessUserDetails.at(index).get('id').value : null,
					index,
					businessDetails.get('panCardFile').value ? businessDetails.get('panCardFile').value : null,
				)
			}
		} else if (fileType == 'KYC_DOCUMENT') {
			if (event[0]) {
				this.uploadBusinessSingleFile(
					event[0],
					businessDetails.get('kycDocument').value,
					fileType,
					this.businessUserDetails.at(index).get('id').value ? this.businessUserDetails.at(index).get('id').value : null,
					index,
					businessDetails.get('kycDocumentFile').value ? businessDetails.get('kycDocumentFile').value : null,
				)
			} else {
				this.uploadBusinessSingleFile(
					event.target.files[0],
					businessDetails.get('kycDocument').value,
					fileType,
					this.businessUserDetails.at(index).get('id').value ? this.businessUserDetails.at(index).get('id').value : null,
					index,
					businessDetails.get('kycDocumentFile').value ? businessDetails.get('kycDocumentFile').value : null,
				)
			}
		}
	}
	uploadBusinessSingleFile(file: any, value: any, type: any, directorId: any, index: number, oldDocId: any) {
		this.shieldService.uploadSingleFile(this.laneTaskId, type, value, file, oldDocId, directorId).subscribe({
			next: (resp: any) => {
				if (resp.success) {
					// this.directorId = resp.result && resp.result.directorId ? resp.result.directorId : null
					if (type == 'PAN_CARD') {
						this.businessUserDetails.at(index).patchValue({
							dob: resp.result.ocrData.dob,
							id: resp.result.directorId,
							panCardFile: resp.result.id,
							panCard: resp.result.ocrData.documentNo,
						})
					} else if (type == 'KYC_DOCUMENT') {
						this.businessUserDetails.at(index).patchValue({
							kycDocumentFile: resp.result.id,
							id: resp.result.directorId,
							address: resp.result.ocrData.address,
							gender: resp.result.ocrData.gender,
							city: resp.result.ocrData.city,
							name: resp.result.ocrData.name,
							pincode: resp.result.ocrData.pincode,
							state: resp.result.ocrData.state,
							kycDocument: resp.result.ocrData.documentNo,
						})
					}
				} else {
					this.toasterService.error(resp.message)
				}
			},
		})
	}
	removeEmptyObjects(array: any[]): any[] {
		return array.filter((obj) => {
			// Check if every property in the object is null
			return !Object.values(obj).every((value) => value === null)
		})
	}

	submitForm() {
		// Remove empty objects from the businessUserDetails array
		const cleanedbusinessUserDetails = this.removeEmptyObjects(this.detailForm.getRawValue().businessUserDetails)

		// Create a new payload with the cleaned array
		const payload = {
			...this.detailForm.getRawValue(),
			businessUserDetails: cleanedbusinessUserDetails,
		}
		if (payload.documentationsDTO.businessVintageDTO.udhyamAadharCertificate) {
			if (!((this.detailForm.get('documentationsDTO') as FormGroup).get('businessVintageDTO') as FormGroup).get('udhyamAadharCertificate').valid) {
				this.toasterService.error('Udhyam Aadhar Should Be In XXXXX-XX-00-0000000 Format')
				return
			}
		}
		if (this.detailForm.valid) {
			this.shieldService.saveDetailsAndDocuments(payload, this.laneTaskId).subscribe({
				next: (resp: any) => {
					if (resp.success) {
						this.toasterService.success('Details Saved Successfully!')
					} else {
						this.toasterService.error(resp.message)
					}
					this.fetchdata(this.laneTaskId)
				},
			})
		} else {
			this.focusFirstInvalidField(this.detailForm.controls['companyDetails'])
			this.toasterService.error('Fill Mandatory field First And Upload Files Too')
			console.log(this.detailForm)
		}
	}

	private focusFirstInvalidField(form: any): void {
		for (const field in form.controls) {
			if (form.controls[field].invalid) {
				const invalidControl = form.controls[field]
				const invalidFieldElement = document.getElementById(field)
				console.log('field name', field)
				if (invalidFieldElement) {
					invalidControl.markAsTouched()
					invalidFieldElement.focus()
					return
				}
			}
		}
	}

	viewDocument(type: string) {
		let id = ''
		let details = this.detailForm.get('companyDetails')
		let documents = (this.detailForm.get('documentationsDTO') as FormGroup).get('businessVintageDTO')
		let residenceDocuments = (this.detailForm.get('documentationsDTO') as FormGroup).get('residenceDocuments')
		if (type == 'COMPANY_PAN') {
			id = details.get('companyPANFile').value
		} else if (type == 'CIN_NUMBER') {
			id = details.get('cinNoFile').value
		} else if (type == 'GSTIN_NUMBER') {
			id = details.get('gstinNoFile').value
		}
		//  else if (type == 'UTILITY_BILLS') {
		// 	id = documents.get('utilityBills').value
		// }
		else if (type == 'GST_REGISTRATION') {
			id = documents.get('gstCertificateFile').value
		} else if (type == 'UDHYAM_AADHAR') {
			id = documents.get('udhyamAadharCertificateFile').value
		} else if (type == 'INCORPORATION_CERTIFICATE') {
			id = documents.get('incorporationCertificateFile').value
		} else if (type == 'WATER_BILL') {
			id = residenceDocuments.get('waterBillFile').value
		} else if (type == 'ELECTRICITY_BILL') {
			id = residenceDocuments.get('electricBillFile').value
		} else if (type == 'OWNERSHIP_PROOF') {
			id = residenceDocuments.get('ownerShipProofDoc').value
		}
		this.downloadFile(id)
	}
	viewBusinessDocument(type: string, index: number) {
		let id = ''
		if (type == 'pan') {
			id = this.businessUserDetails.at(index).get('panCardFile').value
		} else {
			id = this.businessUserDetails.at(index).get('kycDocumentFile').value
		}
		this.downloadFile(id)
	}

	downloadFile(id: any) {
		this.shieldService.downloadSingleUploadDocument(id).subscribe({
			next: (resp: any) => {
				this.downloadSheetCommonMethod(resp)
			},
		})
	}
	downloadAllFiles() {
		this.shieldService.downloadAllFiles(this.laneTaskId).subscribe({
			next: (resp: any) => {
				this.downloadSheetCommonMethod(resp)
			},
		})
	}
	resultDownload: any
	downloadSheetCommonMethod(res: any) {
		var contentDispositionHeader = res.headers.get('content-disposition')
		this.resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1]
		let fileName = this.resultDownload.replace(/"/g, '')
		saveAs(res.body, fileName)
		return fileName
	}

	titleCase(str: string): string {
		if (!str) return str
		return str
			.toLowerCase()
			.split(' ')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ')
	}
	showConsent() {
		if (this.businessUserDetails.length > 0 && !this.isEligibleToOnboard && !this.nextLane) {
			this.isReadyForCosent = true
			return true
		} else {
			this.isReadyForCosent = false
			return false
		}
	}
	moveToApprove() {
		let toIdObj = this.laneIdList.filter((x: any) => x.displayName == 'Approved Onboarding')
		let toId = toIdObj[0].laneId
		this.shieldService.laneChangeCall(this.laneTaskId, this.selectedLaneId, toId).subscribe({
			next: (resp: any) => {
				this.toasterService.success('Lane Approved Succesfully!')
			},
			error: (err: any) => {
				this.toasterService.error(err && err.error && err.error.message ? err.error.message : 'Something went wrong!')
				// this.reloadPage();
			},
			complete: () => this.fetchdata(this.laneTaskId),
		})
	}
}

const BANK_LIST = [
	'Andhra Pragathi Grameena Bank',
	'Andhra Pradesh Grameena Vikas Bank',
	'Airtel Payments Bank Ltd',
	'Aryavart Bank',
	'Assam Gramin Vikash Bank',
	'Au Small Finance Bank Ltd.',
	'Axis Bank Ltd.',
	'Bandhan Bank Ltd.',
	'Bangiya Gramin Vikash Bank',
	'Bank of Baroda',
	'Bank of India',
	'Bank of Maharashtra',
	'Baroda Gujarat Gramin Bank',
	'Baroda Rajasthan Kshetriya Gramin Bank',
	'Baroda UP Bank',
	'Canara Bank',
	'Capital Small Finance Bank Ltd',
	'Central Bank of India',
	'Chaitanya Godavari GB',
	'Chhattisgarh Rajya Gramin Bank',
	'City Union Bank Ltd.',
	'Coastal Local Area Bank Ltd',
	'CSB Bank Limited',
	'Dakshin Bihar Gramin Bank',
	'DCB Bank Ltd.',
	'Dhanlaxmi Bank Ltd.',
	'Ellaquai Dehati Bank',
	'Equitas Small Finance Bank Ltd',
	'ESAF Small Finance Bank Ltd.',
	'Export-Import Bank of India',
	'Federal Bank Ltd.',
	'FINO Payments Bank Ltd',
	'HDFC Bank Ltd.',
	'Himachal Pradesh Gramin Bank',
	'ICICI Bank Ltd.',
	'IDBI Bank Limited',
	'IDFC FIRST Bank Limited',
	'India Post Payments Bank Ltd',
	'Indian Bank',
	'Indian Overseas Bank',
	'IndusInd Bank Ltd.',
	'J&K Grameen Bank',
	'Jammu & Kashmir Bank Ltd.',
	'Jana Small Finance Bank Ltd',
	'Jharkhand Rajya Gramin Bank',
	'Jio Payments Bank Ltd',
	'Karnataka Bank Ltd.',
	'Karnataka Gramin Bank',
	'Karnataka Vikas Gramin Bank',
	'Karur Vysya Bank Ltd.',
	'Kerala Gramin Bank',
	'Kotak Mahindra Bank Ltd.',
	'Krishna Bhima Samruddhi LAB Ltd',
	'Madhya Pradesh Gramin Bank',
	'Madhyanchal Gramin Bank',
	'Maharashtra Gramin Bank',
	'Manipur Rural Bank',
	'Meghalaya Rural Bank',
	'Mizoram Rural Bank',
	'Nainital Bank Ltd.',
	'Nagaland Rural Bank',
	'National Bank for Agriculture and Rural Development',
	'National Housing Bank',
	'North East Small Finance Bank Ltd',
	'NSDL Payments Bank Limited',
	'Odisha Gramya Bank',
	'Paschim Banga Gramin Bank',
	'Paytm Payments Bank Ltd',
	'Prathama U.P. Gramin Bank',
	'Punjab & Sind Bank',
	'Punjab Gramin Bank',
	'Punjab National Bank',
	'Puduvai Bharathiar Grama Bank',
	'Rajasthan Marudhara Gramin Bank',
	'RBL Bank Ltd.',
	'Saptagiri Grameena Bank',
	'Sarva Haryana Gramin Bank',
	'Saurashtra Gramin Bank',
	'Shivalik Small Finance Bank Ltd',
	'Small Industries Development Bank of India',
	'South Indian Bank Ltd.',
	'State Bank of India',
	'Suryoday Small Finance Bank Ltd.',
	'Tamil Nadu Grama Bank',
	'Tamilnad Mercantile Bank Ltd.',
	'Telangana Grameena Bank',
	'Tripura Gramin Bank',
	'UCO Bank',
	'Ujjivan Small Finance Bank Ltd.',
	'Union Bank of India',
	'Unity Small Finance Bank Ltd',
	'Utkal Grameen Bank',
	'Uttarakhand Gramin Bank',
	'Uttar Bihar Gramin Bank',
	'Uttarbanga Kshetriya Gramin Bank',
	'Vidharbha Konkan Gramin Bank',
	'YES Bank Ltd.',
	'Others',
]
