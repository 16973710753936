import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ShieldApiService } from 'src/app/service/shield-api.service';

@Component({
  selector: 'app-add-remark',
  templateUrl: './add-remark.component.html',
  styleUrls: ['./add-remark.component.scss']
})
export class AddRemarkComponent implements OnInit {

  textMessage : string;
  remarksList : any = [];

  constructor(private shieldService : ShieldApiService, @Inject(MAT_DIALOG_DATA) public data : any, private tostrService : ToastrService, private dialogRef : MatDialogRef<AddRemarkComponent>) { }

  ngOnInit(): void {
    this.getRemarks(this.data.laneTaskId)
  }

  getRemarks(id : string){
    let obj = {
    "laneTaskId" : this.data.laneTaskId,
	  "documentId" : this.data.documentId,
    "documentType" : this.data.type,
    "referenceId" : this.data.referenceId
    }
    this.shieldService.getRemarks(obj).subscribe((res : any) =>{
        this.remarksList = res['data'];
    })
  }

  sendMessage(){
    let obj = {
    "remark" : this.textMessage,
	  "laneTaskId" : this.data.laneTaskId,
	  "documentId" : this.data.documentId,
    "documentType" :this.data.type,
    "referenceId" : this.data.referenceId
    }
    this.shieldService.sendRemarks(obj).subscribe((res : any)=>{
      this.textMessage = null;
      this.tostrService.success("Remark Added Successfully");
      this.getRemarks(this.data.laneTaskId);
    });
  }

  closeDialog(){
    this.dialogRef.close(true);
  }

}
