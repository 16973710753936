<div class="main-div">
    <div class="remarkHeadWrap">
        <h3>Remark ({{remarksList && remarksList.length > 0 ? remarksList.length : 0}})</h3>
        <button class="cancel" (click)="closeDialog()"><i class="ri-close-line"></i></button>
    </div>

    <section class="remarkRowWise">
        <div class="comment d-flex justify-between" *ngFor="let data of remarksList">
            <section class="userSectionWrap">
                <div class="userNameTxt">
                    <img class="userimg" src="../../../../../../../assets/img/userPofile.svg" />
                    <div class="userTxt">{{data.providedBy && data.providedBy.name ? data.providedBy.name : 'Unknown User'}}</div>
                </div>
                <div class="dateTextWrap">
                    {{data.date ? (data.date | date) : 'Date Unavailable'}}
                </div>
            </section>
            <section class="bankDiscpWrap">
                {{data.remark}}.
            </section>
        </div>
    </section>

    <div class="input-div d-flex rBottomControl">
        <div class="controlCta">
            <input type="text" class="comment-input" placeholder="Comment here" [(ngModel)]="textMessage" />
            <button type="button" class="sendMsgCta" (click)="sendMessage()">
                <i class="ri-send-plane-2-line"></i>
            </button>
        </div>
    </div>
</div>