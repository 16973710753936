import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import { saveAs } from "file-saver";
import { AddRemarkComponent } from './add-remark/add-remark.component';
import { DocListActionComponent } from '../doc-list-action/doc-list-action.component';
import { FileListComponent } from './file-list/file-list.component';
import { RejectDialogComponent } from '../reject-dialog/reject-dialog.component';
import { DeferredDocDialogComponent } from './deferred-doc-dialog/deferred-doc-dialog.component';
import * as moment from 'moment';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-under-writting-doc',
  templateUrl: './under-writting-doc.component.html',
  styleUrls: ['./under-writting-doc.component.scss']
})
export class UnderWrittingDocComponent implements OnInit {

  laneTaskId: any;
  selectedDate: any;
  data: any = {};

  constructor(
    public dialog: MatDialog,
    public toasterService: ToastrService,
    private activatedRoute: ActivatedRoute,
    public shieldService: ShieldApiService,
    private router: Router
  ) { }

  dataSource2: any = [];
  displayColumns1: Array<string> = ["Documents", "Status", "Remarks", "Action"];

  ngOnInit() {
    this.activatedRoute.params.subscribe((x) => {
      this.laneTaskId = x['id'];
      this.shieldService.setLaneTaskId(x['id']);
    });
    this.fetchdata(this.laneTaskId);
  }
  fetchdata(x: any) {
    if (x !== null && x !== "undefined") {
      this.shieldService.fetchData(x).subscribe({
        next: (resp: any) => {
          this.shieldService.setLaneData(resp.data);
          let businessProduct = resp.data.applicationPreview.leadDetails.businessProduct;
          if (businessProduct == 'SID' || businessProduct == 'DEALER_FINANCE') {
            this.shieldService.setBuyerValue('Buyer');
          } else {
            this.shieldService.setBuyerValue('Supplier');
          }
        },
      });
    }
    this.fetchLaneData();
  }
  fetchLaneData() {
    this.shieldService.getListing(this.laneTaskId).subscribe({
      next: (resp: any) => {
        // this.data = resp.data;
        let a = resp.data && resp.data.companyDocs ? resp.data.companyDocs : {};
        let b = resp.data && resp.data.residenceDocuments ? resp.data.residenceDocuments : {};
        let c = resp.data && resp.data.businessVintage ? resp.data.businessVintage : {};
        let d = resp.data && resp.data.bankDetails ? resp.data.bankDetails : {};
        let e = resp.data && resp.data.directorDocuments ? resp.data.directorDocuments : {};
        Object.assign(this.data, a);
        Object.assign(this.data, b);
        Object.assign(this.data, c);
        Object.assign(this.data, d);
        Object.assign(this.data, e);
        this.dataSource2 = Object.keys(this.data)
          .map((personNamedIndex) => {
            let person = {
              name: personNamedIndex,
              value: this.data[personNamedIndex],
            };
            return person;
          })
          .filter((x) => {
            if (x) {
              if (Array.isArray(x.value)) {
                return x.value.length > 0;
              } else {
                return x.value;
              }
            }
          });
        console.log("TABLE", this.dataSource2)
      },
    });
  }
  downloadAllFiles() {
    this.shieldService.downloadAllFiles(this.laneTaskId).subscribe({
      next: (resp: any) => {
        this.downloadSheetCommonMethod(resp);
      },
    });
  }
  resultDownload: any;
  downloadSheetCommonMethod(res: any) {
    var contentDispositionHeader = res.headers.get("content-disposition");
    this.resultDownload = contentDispositionHeader
      .split(";")[1]
      .trim()
      .split("=")[1];
    let fileName = this.resultDownload.replace(/"/g, "");
    saveAs(res.body, fileName);
    return fileName;
  }
  checkArray(value: any) {
    return Array.isArray(value);
  }

  changeDefferedStatus(type: any, status: any, date: any) {
    let obj = {
      documentType: type,
      status: status,
      laneTaskId: this.laneTaskId,
      defferedDate: date,
    };
    this.shieldService.changeStatus(obj).subscribe({
      next: (resp: any) => {
        this.toasterService.success("Action Taken Successfully!");
      },
      complete: () => {
        this.fetchdata(this.laneTaskId);
      },
    });
  }
  changeStatus(element: any, status: any, date?: string, remarks?: string) {
    let data = {
      title: "Please Confirm",
      content: status == 'APPROVED' ? 'Are you sure you want to accept this document?' : status == 'DEFERRED' ? 'Are you sure you want to deferred this document?' : '',
      additional: "",
      class: "green"
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      width: '400px',
      height: '220px',
      data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let obj: any = {};
        obj = this.getDocType(element);
        obj['documentType'] = obj['type'];
        delete obj['type'];
        if (date) {
          obj['performedOn'] = date;
        }
        if (remarks) {
          obj['remarks'] = remarks;
        }
        obj['status'] = status;
        obj['laneTaskId'] = this.laneTaskId;
        this.updateLead(obj);
      }
    })
  }

  toTitleCase(str: string) {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  openAllFilesPopUp(element: any, docType: string) {
    let obj = {
      element: element,
      docType: docType,
      laneTaskId: this.laneTaskId
    }
    const dialogRef = this.dialog.open(FileListComponent, {
      width: "500px",
      data: obj,
      panelClass: "file-dialog-container",
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.fetchdata(this.laneTaskId);
    });
  }
  downloadFile(element: any) {
    let id;
    if (element && element.value) {
      id = element.value.documentId ? element.value.documentId : element.value.document.documentId;
    }
    if (id) {
      this.shieldService.downloadSingleUploadDocument(id).subscribe({
        next: (resp: any) => {
          this.downloadSheetCommonMethod(resp);
        },
      });
    } else {
      this.toasterService.error("No File Uploaded!");
    }
  }
  changeFile(id: any, type: any) {
    // Trigger file input click to change the file
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.onchange = (event: any) => {
      const newFile = event.target.files[0];
      this.reUpload(newFile, id, type);
    };
    fileInput.click();
  }
  triggerFolderUpload(): void {
    const folderInput = document.getElementById(
      "folderInput"
    ) as HTMLInputElement;
    if (folderInput) {
      folderInput.click();
    } else {
      console.error("folderInput element is not found");
    }
  }

  // Method to handle folder upload
  handleFolderUpload(event: Event, type: any): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      const newFiles = Array.from(input.files) as File[];
      // Process the files as needed
      this.uploadMultipleFiles(newFiles, "bulk_upload", type);
    }
  }
  reUpload(file: any, id: any, type: any) {
    this.shieldService.reUploadFile(this.laneTaskId, type, file, id).subscribe({
      next: (resp: any) => {
        this.toasterService.success("File Uploded SuccessFull!");
      },
    });
  }

  uploadMultipleFiles(files: any, remark: any, type: any) {
    let formData = new FormData();
    files.forEach((x: any) => {
      formData.append("files", x);
    });
    this.shieldService
      .uploadBulkFiles(formData, this.laneTaskId, remark, type)
      .subscribe({
        next: (resp: any) => {
          this.toasterService.success("Files Uploaded Sucessfully!");
        },
      });
  }

  openAddRemark(element: any) {
    let obj: any = {}
    obj = this.getDocType(element);
    obj['laneTaskId'] = this.laneTaskId;
    const dialogRef = this.dialog.open(AddRemarkComponent, {
      disableClose: true,
      width: "500px",
      data: obj,
      panelClass: "file-dialog-container",
    });
    dialogRef.afterClosed().subscribe((resp: any) => {
      if (resp) {
        this.fetchdata(this.laneTaskId);
      }
    })
  }
  getLength(docObj: {}) {
    return Object.keys(docObj).length
  }
  openDirectorDocDialog(element: any) {
    const dialogRef = this.dialog.open(DocListActionComponent, {
      disableClose: true,
      width: '70%',
      height: "70%",
      data: {
        documentList: element,
        laneTaskId: this.laneTaskId,
        isDirector: true
      }
    });
    dialogRef.afterClosed().subscribe(res => {

    })
  }

  openBankStatementDialog(element: any) {

    const dialogRef = this.dialog.open(DocListActionComponent, {
      disableClose: true,
      width: '70%',
      height: "70%",
      data: {
        documentList: element,
        laneTaskId: this.laneTaskId,
        isBankStatement: true
      }
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.fetchLaneData();
      }
    })
  }

  updateLead(obj: any) {
    this.shieldService.updateLead(obj).subscribe({
      next: () => {
        this.toasterService.success(`${this.toTitleCase(obj.status)} Successfully!`);
      },
      complete: () => {
        this.fetchdata(this.laneTaskId);
      },
    });
  }

  openDialog(component: any, status: string, height: string = '250px') {
    return this.dialog.open(component, {
      disableClose: true,
      width: '500px',
      height: height,
      data: status
    }).afterClosed();
  }

  prepareUpdateObject(element: any, status: string, res: any = null) {
    let obj: any = this.getDocType(element);
    obj['documentType'] = obj['type'];
    delete obj['type'];
    obj['status'] = status;
    obj['laneTaskId'] = this.laneTaskId;
    if (res?.remarks) obj['remarks'] = res.remarks;
    if (res?.deferredDate) obj['performedOn'] = res.deferredDate;
    return obj;
  }

  rejectDialog(element: any, status: string) {
    this.openDialog(RejectDialogComponent, status)
      .subscribe((res: any) => {
        if (res) {
          const obj = this.prepareUpdateObject(element, status, res);
          this.updateLead(obj);
        }
      });
  }

  deferredDoc(element: any, status: string) {
    const component = status === 'WAIVERED' ? RejectDialogComponent : DeferredDocDialogComponent;
    const height = component === RejectDialogComponent ? '250px' : '220px';

    this.openDialog(component, status, height)
      .subscribe(res => {
        if (res) {
          const obj = this.prepareUpdateObject(element, status, res);
          this.updateLead(obj);
        }
        this.fetchLaneData();
      });
  }

  checkFileType(element: any) {
    let obj: any = {}
    obj = this.getDocType(element);
    return this.shieldService.multipleDocsArray.includes(obj['type']);
  }

  getDeferredDate(element: any) {
    let obj: any = {};
    obj = this.getDocType(element);
    return obj['status'] == 'DEFERRED' ? moment(new Date(obj['deferredDate'])).format('Do MMM YYYY') : '';
  }

  fileUpload(event: any, element: any, uploadType: string) {
    let obj: any = {}
    obj = this.getDocType(element);
    if (uploadType == 'multiple') {
      const fileArray = Array.from(event.target.files) as File[];
      const formData = new FormData();
      fileArray.forEach(file => {
        formData.append('files', file);
      });
      this.multipleFileUpload(formData, obj);
    } else {
      let file;
      if (event[0]) {
        file = event[0]
      } else {
        file = event.target.files[0]
      }
      const formData = new FormData();
      formData.append('file', file);
      formData.append('laneTaskId', this.laneTaskId);
      formData.append('docType', obj['type']);
      if (obj['referenceId']) {
        formData.append('referenceId', obj['referenceId']);
      }
      this.singleFileUpload(formData)
    }

  }

  multipleFileUpload(formData: any, obj: any) {
    this.shieldService.uploadBulkFiles(formData, this.laneTaskId, 'bulk upload', obj['type'], obj['refrenceId']).subscribe((res: any) => {
      this.toasterService.success(res['message']);
      this.fetchdata(this.laneTaskId);
    })
  }

  singleFileUpload(formData: any) {
    this.shieldService.uploadFile(formData).subscribe((res: any) => {
      this.toasterService.success(res['message']);
      this.fetchdata(this.laneTaskId);
    })
  }

  getDocType(element: any): { [key: string]: any } {
    const obj: { [key: string]: any } = {};

    const setProperties = (source: any) => {
      obj['type'] = source.type;
      obj['documentId'] = source.documentId;
      obj['status'] = source.status;
      obj['deferredDate'] = source.deferredDate;
    };

    if (!element?.value) return obj;

    const { value } = element;

    if (Array.isArray(value) && value[0]?.type) {
      setProperties(value[0]);
    } else if (value.type) {
      setProperties(value);
    } else if (Array.isArray(value.bankStatements) && value.bankStatements[0]?.type) {
      setProperties(value.bankStatements[0]);
      obj['referenceId'] = value.id;
    } else if (value.document?.type) {
      setProperties(value.document);
      obj['referenceId'] = value.id;
    }

    return obj;
  }

  toggleShowMore(index: number) {
    const element = this.dataSource2[index]; // Assuming dataSource contains the elements of the table
    element.showMore = !element.showMore;
  }

  getTruncatedRemarks(element: any, maxWords: number) {
    let obj: any = {}
    obj = this.getRemarksList(element);
    if (obj['remarks'] && obj['remarks'].length > 0) {
      const remarks = obj['remarks'];
      let totalWords = 0;
      const truncatedRemarks: string[] = [];
      for (let remarkObj of remarks) {
        const words = remarkObj.remark.split(' ');
        if (totalWords + words.length > maxWords) {
          const remainingWords = maxWords - totalWords;
          truncatedRemarks.push(words.slice(0, remainingWords).join(' '));
          break;
        } else {
          truncatedRemarks.push(remarkObj.remark);
          totalWords += words.length;
        }
      }

      return truncatedRemarks;
    }
  }

  getTotalWords(element: any) {
    let obj: any = {};
    obj = this.getRemarksList(element);
    if (obj['remarks'] && obj['remarks'].length > 0) {
      return obj['remarks'].reduce((acc: any, remarkObj: any) => acc + remarkObj.remark.split(' ').length, 0);
    }
  }

  getRemarksList(element: any): { remarks?: any } {
    const getValidRemarks = (remarks: any) => remarks && remarks.length > 0 ? remarks : undefined;

    if (!element?.value) return {};

    const { value } = element;

    return {
      remarks:
        getValidRemarks(Array.isArray(value) ? value[0]?.remarks : value.remarks) ??
        getValidRemarks(Array.isArray(value.bankStatements) ? value.bankStatements[0]?.remarks : undefined) ??
        getValidRemarks(value.document?.remarks)
    };
  }

  isButtonDisabled(element: any, arr: any[]): boolean {
    const hasStatusInArray = (status: any) => status && arr.includes(status);
    if (!element?.value) return false;
    const { value } = element;
    return (
      (Array.isArray(value) && hasStatusInArray(value[0]?.status)) ||
      hasStatusInArray(value.status) ||
      (Array.isArray(value.bankStatements) && hasStatusInArray(value.bankStatements[0]?.status)) ||
      hasStatusInArray(value.document?.status)
    );
  }

}
