<section class="watcherPopupInner">
    <div class="head">
      Status
      <span class="material-icons" (click)="dailogClose()"> cancel </span>
    </div>
    <div class="formFieldsWrap">
      <mat-form-field appearance="outline" (click)="openDropdown($event)">
        <mat-label>Watcher</mat-label>
        <input
          type="text"
          placeholder="Select watcher Name"
          aria-label="Number"
          [(ngModel)]="watcherName"
          matInput
          [matAutocomplete]="auto"
          (input)="filterOptions($event)"
        />
        <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="selectedWatchersList($event)">
          <mat-option *ngFor="let option of filteredOptions" [value]="option.name">
            {{ option.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
  
      <button class="addCta" (click)="assignWatchers()">Add</button>
      <div class="chpipsWrap">
        <span class="common" *ngFor="let element of selectedWatcher">
          {{getUserName(element)}}
          <span class="material-icons" (click)="deleteWatchers(element)"> cancel </span>
        </span>
      </div>
    </div>
  </section>
  