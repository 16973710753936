import { Component, OnInit, ViewChild } from '@angular/core';
import { Renderer2, ElementRef } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Router } from '@angular/router';
import { ShieldApiService } from 'src/app/service/shield-api.service';

@Component({
  // imports: [SharedModule, CommonModule, HttpClientModule],
  // standalone: true,
  selector: 'app-los-listing',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit {
  
  @ViewChild('sidebar') sidebar!: ElementRef;
  userProfile:string;

  constructor(private renderer: Renderer2,
    private router : Router,
    private shieldService: ShieldApiService
  ) {}

  ngOnInit() {
    // this.getPicksList();
    this.setuserName();
  }

  menuClick() {
    this.sidebar.nativeElement.classList.toggle('close');
  }
  AddMenuClick() {
    this.renderer.removeClass(this.sidebar.nativeElement, 'close');
  }

  removeMenuClick() {
    this.renderer.addClass(this.sidebar.nativeElement, 'close');
  }

  toggleDropdpwnMenu(event: MouseEvent) {
    const target = event.currentTarget as HTMLElement;
    const liElement: any = target.parentElement;
    liElement.classList.toggle('showMenu');
  }
  navigateToListing(){
    this.router.navigate(['dashboard/lead-listing']);
  }
  logOut(){
    localStorage.clear();
    this.router.navigate(['login']);
  }
  setuserName(){
    let getLocalStorage =JSON.parse(localStorage.getItem('USER_PROFILE'));
    let userName = getLocalStorage ? getLocalStorage.name : '';
    let matches = userName.match(/\b(\w)/g);
    if (matches && matches.length > 2) {
        matches.splice(2, matches.length)
      }
    if (matches && matches.length > 2){
      this.userProfile = matches.join('').toUpperCase();
    }
  }
}
