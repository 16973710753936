import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ShieldApiService } from 'src/app/service/shield-api.service';

@Component({
  selector: 'app-reject-dialog',
  templateUrl: './reject-dialog.component.html',
  styleUrls: ['./reject-dialog.component.scss']
})
export class RejectDialogComponent implements OnInit {
  rejectReason : string;
  constructor(public dialogRef : MatDialogRef<RejectDialogComponent>, private tostrService : ToastrService, @Inject(MAT_DIALOG_DATA) public data : any, private shieldService : ShieldApiService) { }

  ngOnInit(): void {
  }

  reject(){
    let obj = {
      remarks : this.rejectReason,
    };
    this.dialogRef.close(obj);
  }

}
