import { Component, Inject, OnInit } from '@angular/core'
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ToastrService } from 'ngx-toastr'
import { elementAt } from 'rxjs'
import { ShieldApiService } from 'src/app/service/shield-api.service'

@Component({
	selector: 'app-change-assignee-popup',
	templateUrl: './change-assignee-popup.component.html',
	styleUrls: ['./change-assignee-popup.component.scss'],
})
export class ChangeAssigneePopupComponent implements OnInit {
	userNameList: Array<any> = []
	filteredAssigneeusers: Array<any> = []
	selectedAssignee: any
	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ChangeAssigneePopupComponent>, private shieldApi: ShieldApiService, public toasterService: ToastrService) {}

	ngOnInit() {
		this.getUserList()
	}

	getUserList() {
		this.shieldApi.getKanbanUserList().subscribe((list: any) => {
			if (list.success) {
				this.filteredAssigneeusers = list['data']
				this.userNameList = list['data']
			}
		})
	}
	showList() {
		this.filteredAssigneeusers = this.userNameList
	}
	changeAssigne() {
		let selectedAssignee = this.userNameList.filter((element: any) => {
			return element.name.includes(this.selectedAssignee)
		})
    let obj ={};
		if (selectedAssignee[0]) {
			obj = {
				laneTaskId: this.data.laneTaskId,
				userIds: [selectedAssignee[0].id],
				userType: 'ASSIGNEE',
			}
		} else {
      this.toasterService.error("Please Select User Before Assigning!");
      return;
		}
		this.shieldApi.addWatchersToLaneTask(obj).subscribe({
			next: (res: any) => {
				if (res.success) {
					this.toasterService.success(res.message)
					this.dialogRef.close(true)
				} else {
					this.toasterService.error(res.message)
				}
			},
			error: (error: any) => {
				console.log(error)
				this.toasterService.error('Something went wrong')
			},
		})
	}
	closeAssigneePopup() {
		this.dialogRef.close(true)
	}
	handleAssigneeInp(event: any) {
		this.filteredAssigneeusers = this.userNameList.filter((element: any) => {
			return element.name.toLowerCase().includes(event.target.value.toLowerCase())
		})
	}
	handleAssigneeBlur(event: any) {
		setTimeout(() => {
			if (this.filteredAssigneeusers) {
				let assignee = this.userNameList.filter((element: any) => {
					return element.name.includes(this.selectedAssignee)
				})
				if (assignee.length == 0) {
					this.selectedAssignee = null
				}
				this.filteredAssigneeusers = []
			}
		}, 200)
	}
}
