import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { StorageConst } from '../constant/storage.const';
import { AccessTokenType } from '../type/token/access.token.type';

@Injectable({ providedIn: 'root' })
export class JwtService {
  BEARER_PREFIX = 'Bearer ';
  TOKEN_EXPIRY = 'exp';
  constructor(private readonly storageService: StorageService) {}

  public isTokenValid(tokenString?: string): boolean {
    const accessToken = this.decodeToken(tokenString);
    if (accessToken) {
      const tokenExpiryUTCSeconds = accessToken.exp;
      const tokenNotExpired = this.isTokenNotExpired(tokenExpiryUTCSeconds);
      return tokenNotExpired;
    }
    return false;
  }

  public decodeToken(tokenString?: string): AccessTokenType | null {
    const token = tokenString ? tokenString : this.getToken();
    if (token) {
      const tokenJson = JSON.parse(atob(token.split('.')[1]));
      return tokenJson as AccessTokenType;
    }
    return null;
  }

  public getToken(): string | null {
    let bearerToken = this.getTokenWithBearerPrefix();
    if (bearerToken) {
      const token = bearerToken.split(this.BEARER_PREFIX)[1];
      return token;
    }
    return null;
  }

  public getTokenWithBearerPrefix(): string | null {
    let bearerToken = this.storageService.getItemByName(
      StorageConst.OAUTH_TOKEN
    );
    return bearerToken ? bearerToken : null;
  }

  public hasRole(role: string) {
    try {
      return this.decodeToken()?.roles.includes(role);
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  public hasScope(scope: string) {
    try {
      return this.decodeToken()?.scope.includes(scope);
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  public hasPermission(permission: string) {
    try {
      return this.decodeToken()?.permission.includes(permission);
    } catch (error) {
      console.log(error);
    }
    return false;
  }

  private isTokenNotExpired(expiryTime: number): boolean {
    const dateInUTC = new Date(0);
    const tokenExpirytime = dateInUTC.setUTCSeconds(expiryTime);
    return tokenExpirytime > Date.now();
  }
}
