import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ShieldApiService } from 'src/app/service/shield-api.service';

@Component({
  selector: 'app-application-preview',
  templateUrl: './application-preview.component.html',
  styleUrls: ['./application-preview.component.scss'],
})
export class ApplicationPreviewComponent implements OnInit {
  data$ = new BehaviorSubject<any>(null)
  laneTaskId: any
  private laneTaskIdSubscription: Subscription;
  constructor(public shieldService: ShieldApiService) {}

  ngOnInit(): void {
    this.laneTaskIdSubscription = this.shieldService.laneTaskId.subscribe((x) => {
			this.laneTaskId = x
		})
  }
  tagChange(event:any){
    if (event == 1) {
			this.data$.next({
				callHistory: true,
				laneTaskId: this.laneTaskId,
			})
		} else {
			this.data$.next(null)
		}
  }
}
