import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-document-validation',
  templateUrl: './document-validation.component.html',
  styleUrls: ['./document-validation.component.scss']
})
export class DocumentValidationComponent implements OnInit {

  documentListDataSource: Array<any> = [];
  displayColumns1: Array<string> = ["Documents", "Status", "Remarks", "Action"];
  constructor() { }

  ngOnInit(): void {
    this.table3();
  }
  table3() {
    this.documentListDataSource = [
      {
        Documents: "Adhar Card Front",
        Status: "Pending",
        Remarks:
          "Morbi scelerisque quam lorem, ut consequat dolor lacinia a. Suspendisse nisl magna, malesuada nec molestie in, consectetur eu risus. Quisque viverra tempor varius.",
        Action: "",
      },
      {
        Documents: "Adhar Card back",
        Status: "Approve",
        Remarks:
          "Morbi scelerisque quam lorem, ut consequat dolor lacinia a. Suspendisse nisl magna, malesuada nec molestie in, consectetur eu risus. Quisque viverra tempor varius.",
        Action: "",
      },
    ];
  }

  checkArray(value : any){

  }

  openAllFilesPopUp(name : string){

  }

  downloadFile(id : string){

  }

  changeStatus(name : string, status : string){

  }

  openDefferedDialouge(name : string, status : string){

  }

  handleFolderUpload(event: any, name: string){

  }

  changeFile(id : string, name : string){

  }

  triggerFolderUpload(){
    
  }
}
