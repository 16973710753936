import { NativeDateAdapter } from '@angular/material/core';

export class AddCustomHoursAdapter extends NativeDateAdapter {
  private readonly timeZoneOffset = 5.5 * 60 * 60 * 1000; // UTC+5:30 offset in milliseconds

  override parse(value: any): Date | null {
    if (typeof value === 'string') {
      const [day, month, year] = value.split('/');
      const date = new Date(+year, +month - 1, +day);
      return new Date(date.getTime() - this.timeZoneOffset); // Adjust for UTC+5:30
    }
    return super.parse(value);
  }

  override format(date: Date, displayFormat: string): string {
    // Adjust date for UTC+5:30 timezone
    const offsetDate = new Date(date.getTime() + this.timeZoneOffset);

    if (displayFormat === 'Do MMM YYYY') {
      return new Intl.DateTimeFormat('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        timeZone: 'Asia/Kolkata',
      }).format(offsetDate);
    }
    
    return super.format(date, displayFormat);
  }
}
