import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShieldApiService } from 'src/app/service/shield-api.service';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss']
})
export class FileListComponent implements OnInit {

  fileData: any = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private shieldService: ShieldApiService, private tostrService: ToastrService, private dialogRef: MatDialogRef<FileListComponent>) { }

  ngOnInit(): void {
    console.log("File Data", this.data)
    if (this.data.element.value && this.data.element.value.length > 0 && this.data.docType != 'bankDoc') {
      this.fileData = this.data.element.value;
    } else if(this.data && this.data.element && this.data.element.value.bankStatements && this.data.element.value.bankStatements.length > 0 && this.data.docType == 'bankDoc'){
      this.fileData = this.data.element.value.bankStatements;
    }
  }
  downloadDocument(id: string) {
    this.shieldService.downloadSingleUploadDocument(id).subscribe((res: any) => {
      this.downloadSheetCommonMethod(res);
    })
  };
  downloadSheetCommonMethod(res: any) {
    var contentDispositionHeader = res.headers.get('content-disposition')
    let resultDownload = contentDispositionHeader.split(';')[1].trim().split('=')[1]
    let fileName = resultDownload.replace(/"/g, '');
    this.tostrService.success("Downloaded Successfully !");
    saveAs(res.body, fileName);
    return fileName;
  }

  reUploadFile(file: any) {
    // Trigger file input click to change the file
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.onchange = (event: any) => {
      const newFile = event.target.files[0];
      this.reUpload(newFile, file);
    };
    fileInput.click();
  }

  reUpload(file: any, element: any) {
    const formData = new FormData();
    formData.append('laneTaskId', this.data.laneTaskId);
    formData.append('docType', element.type);
    formData.append('file', file);
    formData.append('oldDocId', element.documentId);
    this.shieldService.uploadFile(formData).subscribe((res: any) => {
      this.tostrService.success("File Uploded SuccessFull!");
      this.dialogRef.close(true);
    })
  }

  removeFile(file: any) {
    let obj = {
      documentId : file.documentId,
      documentName : file.type,
      referenceId : file.id,
      id : this.data.laneTaskId
    }
    this.shieldService.deleteDocFile(obj).subscribe((res : any) =>{
      this.tostrService.success("File Deleted Sucessfully!");
      this.dialogRef.close(true);
    })
  }

}
