<section aria-label="tags" class="dialog-top">
    <h4 class="heading-assign">Assign To Team</h4>
    <div class="inner-div">
        <mat-form-field class="tag-autocomplete" appearance="outline">
            <mat-label>Change Assignee</mat-label>
            <input type="text" matInput [matAutocomplete]="auto" [(ngModel)]="selectedAssignee"
                style="text-transform: capitalize;" (input)="handleAssigneeInp($event)"
                (blur)="handleAssigneeBlur($event)" (click)="showList()">
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredAssigneeusers" [value]="option.name"
                    style="text-transform: capitalize;">
                    {{option.name}}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
        <div class="cta-box">
            <button class="btncancel" (click)="closeAssigneePopup()" type="button">Cancel</button>
            <button class="btnSave" (click)="changeAssigne()" type="button">Submit</button>
        </div>
    </div>
</section>