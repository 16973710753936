<div class="confirmDialog" [style.color]="modelData['class'] == 'red'? '#D9232D' : ''">
    <h3>{{modelData['title']}} <i class="ri-question-fill"></i></h3>
    <hr>
    <div class="wrap">
      <div [ngClass]="modelData['class'] == 'red'? 'txtRed' : ''">
        
        <div>
          <span *ngIf="modelData['additional']">{{modelData['additional']}}<br></span>
          <span class="wrpContent">{{modelData['content']}}</span>
        </div>
      </div>
      <hr>
    </div>
    <div class="actionBtn">
      <button (click)="dialogRef.close(true)" mat-raised-button class="savebtn">Yes</button>
      <button (click)="dialogRef.close(false)" mat-raised-button class="savebtn ml-20">No</button>
    </div>
  </div>