import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bureau-report',
  templateUrl: './bureau-report.component.html',
  styleUrls: ['./bureau-report.component.scss']
})
export class BureauReportComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
