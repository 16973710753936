import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { JwtService } from '../service/jwt.service';
import { AppUrls } from '../constant/app.url.const';
import { OAuthService } from '../service/oauth.service';

export const OAuthGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const jwtService = inject(JwtService);
  const oauthService = inject(OAuthService);

  if (jwtService.isTokenValid()) {
    return true;
  } else {
    oauthService.logout();
    router.navigateByUrl(AppUrls.LOGIN_PAGE);
    return false;
  }
};
