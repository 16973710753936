import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, MatNativeDateModule } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JWT_HTTP_INTERCEPTOR } from './interceptor/jwt.interceptor';
import { SideBarModule } from './module/lead-listing/side-bar.module';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { SharedModule } from './module/shared/shared.module';
import { LoginComponent } from './module/login/login.component';
import { AddCustomHoursAdapter } from './constant/AddCustomHoursAdapter';
const MY_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD/MM/YYYY', // Input parsing format
  },
  display: {
    dateInput: 'Do MMM YYYY', // Display format in the input
    monthYearLabel: 'MMM YYYY', // Month-Year dropdown label format
    dateA11yLabel: 'LL', // Accessibility format
    monthYearA11yLabel: 'MMMM YYYY', // Accessibility month-year label format
  },
};
@NgModule({
  declarations: [AppComponent,LoginComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatNativeDateModule,    
    SideBarModule,
    RouterModule
  ],
  providers: [
    JWT_HTTP_INTERCEPTOR,
    { provide: DateAdapter, useClass: AddCustomHoursAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}



