export const ApiUrlPath = {
  fetchDomesticData: 'lead/get-lead-by-id', //add urls
  fetchByPan: '',
  fetchByGst: '',
  saveNewLeadData: 'lead/update-lead',
  uploadMappingHeaders: 'common/get-headers-with-records',
  bulkUpload: 'lead/bulk-upload-document',
  fetchByCin: '',
  uploadSingleNonOcr: 'lead/upload-document',
  saveDetailsAndDocument: 'lead/update-lead-details-documentation/',
  downloadSingleDocument: 'document/download-attachment',
  downloadAllFiles: 'lead/download-uploaded-files/',
  changeLane: '',
  getUserManagement: 'user/get-all-active-users',
  showAll: 'lead/details-company-documents/',
  deleteFile: 'lead/delete-company-document/',
  replace: '',
  fetchLaneWithAccess: '',
  getDefaultScoreCard: 'scorecard/config-data',
  getBuyerDetail: 'scorecard/score-doc',
  addWatchers: 'lead/add-assignee-watchers',
  removeWatchers: 'lead/remove-watcher',
  getEPComments: '',
  commentTaskSearch: 'comment/search',
  addComment: 'comment/add-comment',
  getLeadList: 'lead/documents',
  getRemarks: 'lead/remark',
  addRemarks: 'lead/add-remark',
  updateLead: 'lead/update-status',
  getLeadListing: 'es-index-search/lanetasks-listing',
  getLeadListingCount: 'es-index-search/lanetasks-counts',
  getLeadBySearchText: 'es-index-search/auto-suggest-lane-tasks',
  sendToLms: '',
  chnageAssignee: '',
  markDeleted: 'document/mark-deleted',
  saveScoreCard : 'scorecard/save-all',
  stageMovement : 'lead/update-lanetask-status',
  laneApproval : 'lead/update-lane-approvals',
  //GSt Automate
  shareGSTLink: "lead/share-gst-automate-verification-link",
  getGstListByPan: 'api/gst/get-gsts-by-pan',
  getGstReport: "api/gst/get-consolidated-gst-report",
  getSingleGSTReport: 'api/gst/get-gst-report-by-gst',
  gstDownloadFile: 'api/gst/download-file',
};
