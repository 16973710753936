import { Injectable } from '@angular/core';
import { ngxCsv } from 'ngx-csv';
import { environment } from 'src/environments/environment';
import { ApiUrlPath } from '../constant/api.url.const';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class CsvExportService {
  readonly BaseUrl = environment.BASEURL;
  constructor(private http: HttpClient) {}
  downloadFile(data: any, filename: string = 'data') {
    let csvData = this.ConvertToCSV(data);
    let blob = new Blob(['\ufeff' + csvData], {
      type: 'text/csv;charset=utf-8;',
    });
    let dwldLink = document.createElement('a');
    let url = URL.createObjectURL(blob);
    let isSafariBrowser =
      navigator.userAgent.indexOf('Safari') !== -1 &&
      navigator.userAgent.indexOf('Chrome') === -1;

    if (isSafariBrowser) {
      //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute('target', '_blank');
    }
    dwldLink.setAttribute('href', url);
    dwldLink.setAttribute('download', filename + '.csv');
    dwldLink.style.visibility = 'hidden';
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray: any) {
    let array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (let index in objArray[0]) {
      // Convert camelCase to space-separated words
      let header = this.camelCaseToSpaces(index);
      row += header + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in array[i]) {
        if (line !== '') line += ',';
        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }

  // Helper function to convert camelCase text to space-separated
  camelCaseToSpaces(str: string): string {
    return (
      str
        // Insert a space before all caps
        .replace(/([A-Z])/g, ' $1')
        // Uppercase the first character
        .replace(/^./, function (match) {
          return match.toUpperCase();
        })
    );
  }
  downloadCsv(dataArr: any, fileName: string, headerColumn: any) {
    var options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      title: '',
      useBom: true,
      noDownload: false,
      headers: headerColumn,
    };
    new ngxCsv(dataArr, fileName, options);
  }
  getUploadMappingHeaders(obj: any) {
    // let formatURL= "https://apiqa.credlixlab.com/get-headers-with-records";
    let url = this.BaseUrl + ApiUrlPath.uploadMappingHeaders;
    return this.http.post(url, obj);
  }
}
