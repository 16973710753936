<div class="main">
    <div class="header">
        <div>
            <h3 class="heading">{{this.data.isDirector ? 'Director' : 'Bank Statement'}} Documents</h3>
        </div>
        <div>
            <span class="icon" (click)="closeDialog()"><i class="ri-close-circle-line"></i></span>
        </div>
    </div>
    <div class="tableList">
        <div class="mat_table mog3_msnsearchtable" fxFlex="100">
            <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
                <ng-container *ngIf="this.data.isDirector">
                    <ng-container matColumnDef="dirName">
                        <th mat-header-cell *matHeaderCellDef> Director Name</th>
                        <td mat-cell *matCellDef="let element">{{data.documentList.name ? (data.documentList.name) : '--'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="docName">
                        <th mat-header-cell *matHeaderCellDef> Doc Name</th>
                        <td mat-cell *matCellDef="let element">{{element.name ? (element.name).replaceAll("_"," ") : '--'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> Status</th>
                        <td mat-cell *matCellDef="let element">{{element.value ? (element.value.status) : '--'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="doc">
                        <th mat-header-cell *matHeaderCellDef> Document</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="fileboxwrap d-flex al-center justify-between">
                                <div class="left" (click)="downloadDocument(element.value.documentId)">
                                    <p [title]="element.value.name">{{ element.value.name }}</p>
                                </div>

                                <div class="right d-flex al-center" *ngIf="element.value.status !== 'ACCEPTED'">
                                    <button (click)="removeFile(element)"><i class="ri-delete-bin-fill"></i> </button>
                                    <button (click)="reUploadFile(element, 'director')" class="edit"> Re-Upload</button>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>Action</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="d-flex al-center flex-nowrap buttonContainer">
                                <ng-container>
                                    <button class="accept-cta" type="button" (click)="acceptDoc(element, 'APPROVED')">Accept </button>
                                    <button class="reject-cta" type="button" (click)="rejectDialog(element, 'REJECTED')"> Reject</button>
                                </ng-container>
                            </div>
                        </td>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="this.data.isBankStatement">
                    <ng-container matColumnDef="bankName">
                        <th mat-header-cell *matHeaderCellDef> Bank Name</th>
                        <td mat-cell *matCellDef="let element">{{(data.documentList.name | titlecase)}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> Status</th>
                        <td mat-cell *matCellDef="let element">{{element.status ? (element.status) : '--'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="doc">
                        <th mat-header-cell *matHeaderCellDef> Document</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="fileboxwrap d-flex al-center justify-between">
                                <div class="left" (click)="downloadDocument(element.documentId)">
                                    <p [title]="element.name">{{ element.name }}</p>
                                </div>

                                <div class="right d-flex al-center" *ngIf="element.status !== 'ACCEPTED'">
                                    <button (click)="removeFile(element)"><i class="ri-delete-bin-fill"></i> </button>
                                    <button (click)="reUploadFile(element)" class="edit"> Re-Upload</button>
                                </div>
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef>Action</th>
                        <td mat-cell *matCellDef="let element">
                            <div class="d-flex al-center flex-nowrap buttonContainer">
                                <ng-container>
                                    <button class="accept-cta" type="button" (click)="acceptDoc(element,'APPROVED')">Accept </button>
                                    <button class="reject-cta" type="button" (click)="rejectDialog(element, 'REJECTED')"> Reject</button>
                                </ng-container>
                            </div>
                        </td>
                    </ng-container>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>