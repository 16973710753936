import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppUrls } from './constant/app.url.const';
import { OAuthGuard } from './guard/auth.guard';
import { LoginGuard } from './guard/login.guard';
import { RoleAdminGuard } from './guard/role.admin.guard';
import { LoginComponent } from './module/login/login.component';

const routes: Routes = [
  { path: '', redirectTo: AppUrls.LOGIN_PAGE, pathMatch: 'full' },
  {
    path: AppUrls.LOGIN_PAGE,
    component: LoginComponent,
    canActivate: [LoginGuard],
  },
  {
    path: AppUrls.DASHBOARD,
    loadChildren: () =>
      import('./module/lead-listing/side-bar.module').then(
        (m) => m.SideBarModule
      ),
    canActivate: [OAuthGuard],
  },
  {
    path: AppUrls.ADMIN,
    loadChildren: () =>
      import('./module/admin/admin.module').then((m) => m.AdminModule),
    canActivate: [RoleAdminGuard],
  },

  { path: '**', redirectTo: AppUrls.LOGIN_PAGE, pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
