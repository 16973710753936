<div>
  <div class="button-container">
  <div class="upload-container">
    <input id="fileInput" style="display: none;" type="file" (change)="onFileChange($event)" accept=".xlsx, .xls"
      [disabled]="fileUploaded" />

    <label for="fileInput" class="upload-label">
      <div class="upload-box">
        <i class="ri-upload-2-line fa-upload"></i>
        <span *ngIf="!uploadedFileName && !selectedFileName">Upload File</span>
        <span *ngIf="uploadedFileName">{{uploadedFileName}}</span>
        <span *ngIf="!uploadedFileName && selectedFileName">{{ selectedFileName }}</span>
      </div>
    </label>
    <button *ngIf="uploadedFileName || selectedFileName" class="ri-close-line cross-icon"
      (click)="deleteFile($event)"></button>
  </div>
  <div class="download-container">
    <button class="download-btn" (click)="downloadFile()" [disabled]="!uploadedFileName && !selectedFileName">
      <i class="ri-download-2-line"></i> Download File
    </button>
  </div>
</div>


  <div *ngIf="jsonData && jsonData.length > 0">
    <div class="tab-container">
      <button *ngFor="let sheet of jsonData; let i = index" [class.active]="i === activeTab" (click)="setActiveTab(i)">
        {{ sheet.name }}
      </button>
    </div>

    <div class="sheet-data scroll-container">
      <table>
        <tr *ngFor="let row of jsonData[activeTab]?.data">
          <td *ngFor="let cell of row" [style.width.px]="getColumnWidth(cell)">
            {{ cell !== undefined && cell !== null && cell !== '' ? cell : '' }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>