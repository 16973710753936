<div class="main-dialog-box">
    <div class="listingDialog">
        <h3><a><i (click)="dialogRef.close(false)" class="ri-close-line alignClose"></i></a></h3>
        <div class="heading">
            <h3>{{(data == 'WAIVERED' ? 'Waive' : 'Reject') | titlecase}} Reason</h3>
        </div>
        <div class="rejectDiv">
            <textarea class="rejectBox" [(ngModel)]="rejectReason"></textarea>
        </div>
        <div class="btn-footer">
            <button mat-raised-button type="button" class="btnSave" (click)="reject()"
                [disabled]="!rejectReason">{{data == 'WAIVERED' ? 'Waive' : 'Reject'}}</button>
        </div>
    </div>
</div>