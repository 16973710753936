import { Injectable } from '@angular/core';
import { StorageConst } from '../constant/storage.const';

@Injectable({ providedIn: 'root' })
export class StorageService {
  constructor() {}

  getItemByName(name: string): string | null {
    try {
      return localStorage.getItem(name);
    } catch (e) {
      console.log(e);
    }

    return null;
  }

  setItemByName(name: string, value: string): boolean {
    try {
      localStorage.setItem(name, value);
      return true;
    } catch (e) {
      console.log(e);
    }

    return false;
  }

  removeItemByName(name: string): boolean {
    try {
      localStorage.removeItem(name);
      return true;
    } catch (e) {
      console.log(e);
    }

    return false;
  }

  clearStorage(): boolean {
    try {
      localStorage.clear();
      return true;
    } catch (e) {
      console.log(e);
    }
    return false;
  }
}
