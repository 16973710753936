import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { ToastrService } from 'ngx-toastr'
import { ShieldApiService } from 'src/app/service/shield-api.service'

@Component({
	selector: 'app-watcher-popup',
	templateUrl: './watcher-popup.component.html',
	styleUrls: ['./watcher-popup.component.scss'],
})
export class WatcherPopupComponent implements OnInit {
	watcherName: any
	laneTaskDetails: any
	options: any
	selectedWatcher: any = []
	optionsSelected: any
	optionsSelectedId: any
	@ViewChild(MatAutocompleteTrigger) autocomplete!: MatAutocompleteTrigger
	constructor(public shieldService: ShieldApiService, @Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<WatcherPopupComponent>, public toaster: ToastrService) {}

	ngOnInit() {
		this.getUserList()
		this.laneTaskDetails = this.data.laneTaskDetails
		this.selectedWatcher = this.data ? this.data.laneTaskDetails.watchers : []
	}
	userlist: any = []
	getUserList() {
		this.shieldService.getKanbanUserList().subscribe((list: any) => {
			if (list.success) {
				this.options = list['data']
				this.filteredOptions = this.options
				this.selectedWatcher = this.data?.laneTaskDetails?.watchers ? this.data.laneTaskDetails.watchers : []
			}
		})
	}

	assignWatchers() {
		let req: any
		let error = false
		let selectedId = this.getSelectedWatcherDetails(this.optionsSelected)
		if (this.selectedWatcher && this.selectedWatcher.length > 0) {
			for (let i = 0; i < this.selectedWatcher.length; i++) {
				if (this.selectedWatcher[i]) {
					if (this.selectedWatcher[i] == this.optionsSelectedId) {
						this.toaster.error('Selected watcher is already exists')
						error = true
						return
					}
				}
			}
		}
		if (!error) {
			req = {
				laneTaskId: this.data.laneTaskDetails.id,
				userIds: selectedId,
				userType: 'WATCHER',
			}
			this.addWatchersInLaneTask(req)
		}
	}
	addWatchersInLaneTask(req: any) {
		this.shieldService.addWatchersToLaneTask(req).subscribe((res: any) => {
			if (res.success) {
				if (!this.selectedWatcher) {
					this.selectedWatcher = []
				}
				this.selectedWatcher.push(this.getSelectedWatcherDetails(this.optionsSelected))
				this.watcherName = null
				this.filteredOptions = this.options
			} else {
				this.toaster.error(res.message)
			}
		})
	}
	filteredOptions: any // Initially, the filtered options are the same as the full options
	filterOptions(event: any) {
		this.filteredOptions = this.options.filter((element: any) => {
			return element.name.toLowerCase().includes(event.target.value.toLowerCase());
		})
	}

	openDropdown(event: Event) {
		this.autocomplete.openPanel()
		event.stopPropagation()
	}

	getSelectedWatcherDetails(optionsSelected: any) {
		let array: any[] = []
		for (let i = 0; i < this.options.length; i++) {
			if (this.options[i].name == optionsSelected) {
				array.push(this.options[i].id)
				this.optionsSelectedId = this.options[i].id
			}
		}
		return array
	}
	getUserName(id: any) {
		let name: string = ''
		if (this.options) {
			this.options.forEach((element: any) => {
				if (id == element.id) {
					name = element.name
				}
			})
		}
		return name
	}

	selectedWatchersList(event: any) {
		let newValue = event.option.viewValue
		this.optionsSelected = newValue
	}

	deleteWatchers(id: any) {
		console.log(id)
		console.log(this.data.laneTaskDetails.id)
		this.shieldService.removeWatchers(id, this.data.laneTaskDetails.id).subscribe((res: any) => {
			if (res.success) {
				const index = this.selectedWatcher.indexOf(id)
				if (index >= 0) {
					this.selectedWatcher.splice(index, 1)
				}
				this.toaster.success(res.message)
			} else {
				this.toaster.error(res.message)
			}
		})
	}
	dailogClose() {
		this.dialogRef.close('refresh')
	}
}
