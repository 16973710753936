<div>
  <div class="button-container">
    <div class="upload-container">
      <input id="fileInput" style="display: none;" type="file" (change)="onFileChange($event)" accept=".pdf"
        [disabled]="fileUploaded" />

      <label for="fileInput" class="upload-label">
        <div class="upload-box">
          <i class="ri-upload-2-line fa-upload"></i>
          <span *ngIf="!uploadedFileName && !selectedFileName">Upload File</span>
          <span *ngIf="uploadedFileName">{{uploadedFileName}}</span>
          <span *ngIf="!uploadedFileName && selectedFileName">{{ selectedFileName }}</span>
        </div>
      </label>
      <button *ngIf="uploadedFileName || selectedFileName" class="ri-close-line cross-icon"
        (click)="deleteFile($event)"></button>
    </div>
    <div class="download-container">
      <button class="download-btn" (click)="downloadFile()" [disabled]="!uploadedFileName && !selectedFileName">
        <i class="ri-download-2-line"></i> Download File
      </button>
    </div>
  </div>

  <div *ngIf="isPdfShow">
    <iframe [src]="pdfSrc" width="100%" height="1000px"></iframe>
  </div>


</div>