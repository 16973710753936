<!-- <div #sidebar class="atom-sidebar close" (mouseover)="AddMenuClick()" (mouseout)="removeMenuClick()">
  <ul class="nav-links">
    <li>
      <a href="javascript:void(0)">
        <i class="ri-article-fill active"></i>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)">
        <i class="ri-scan-2-fill"></i>
      </a>
    </li>
  </ul>
  <ul class="nav-links bottomNavItem">
    <li>
      <a href="javascript:void(0)">
        <i class="ri-settings-4-fill"></i>
      </a>
    </li>
    <li>
      <a href="javascript:void(0)">
        <i class="ri-scan-2-fill"></i>
      </a>
    </li>
  </ul>
</div> -->

<section class="shieldFinanceMainWrap">
  <div class="shieldAsideNavBar"></div>
  <main class="losMainArea">
    <ul>
      <li class="active">Domestic LOS</li>
    </ul>
    <mat-tab-group class="outline-tab" animationDuration="0ms" mat-align-tabs="start"
      (selectedTabChange)="onTabChange($event)">
      <mat-tab *ngFor="let tab of totalTabsList">
        <ng-template mat-tab-label>
          {{tab.displayName}}
          <span class="noTag">{{tab.count}}</span>
        </ng-template>

        <div style="display: flex; justify-content: space-between; align-items: center;">
          <div class="srchBoxContent" style="flex: 1; display: flex; align-items: center;">
            <i class="ri-search-line"></i>
            <input type="text" placeholder="Search" [(ngModel)]="searchTerm" (input)="onSearch()"
              (blur)="blurSearchList()" (keydown.enter)="addSelectedItem(searchTerm)" />
          </div>

          <div style="margin-right: 16px">
            <button type="button" class="btn-outline-primary save-cta" (click)="resetFilter()">
              Reset <mat-icon class="resetIcn">autorenew</mat-icon>
            </button>
          </div>
        </div>

        <!-- Display the search results -->
        <ul *ngIf="filteredData.length > 0" class="search-results">
          <li *ngFor="let item of filteredData" (click)="onSelectItem(item)">
            Domestic- {{ item?.displaySequence }} | {{ item?.supplierName }}
          </li>
        </ul>

        <!-- <div style="display: flex;">
          <div class="srchBoxContent col-md-6">
            <i class="ri-search-line"></i>
            <input type="text" placeholder="Search" [(ngModel)]="searchTerm" (input)="onSearch()"
              (blur)="blurSearchList()" (keydown.enter)="addSelectedItem(searchTerm)" />

            <button>
            </button>

            <ul *ngIf="filteredData.length > 0" class="search-results">
              <li *ngFor="let item of filteredData" (click)="onSelectItem(item)">
                Domestic- {{ item?.displaySequence }} | {{ item?.supplierName }}
              </li>
            </ul>
          </div>

          <div class="col-md-6">
            <button type="submit" class="btn-outline-primary save-cta">Reset<mat-icon class="resetIcn"
              (click)="resetFilter()">autorenew</mat-icon></button>
          </div>

         
        </div> -->




        <div class="mat-table-overflow custOrderHistoryListingWrap">

          <table mat-table class="mat-table-theme horizontal-overflow-width" [dataSource]="dataSource">

            <ng-container matColumnDef="leadDetails">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20"> Lead Details </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <div class="flex flex-row">
                  <div>
                    <span (click)="routeToDetailsPreviewPage(element.id)" matTooltip="Edit Lead">
                      <p class="blueTxt link">Domestic-{{element.displaySequence}}</p>
                    </span>
                    <p>Created :{{element.createdAt | date: 'dd-MMM-yyyy' }}</p>
                    <p>Modified : {{ element.updatedAt | date: 'dd-MMM-yyyy' }}</p>
                  </div>
                </div>
              </td>
            </ng-container>

            <ng-container matColumnDef="companyDetails">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Company Details
                  <!-- <i *ngIf="!isComanyDetailsFilter" class="ri-arrow-up-down-line unFilteredIcon"
                    (click)="applyComanyFilter()"></i>
                  <i *ngIf="isComanyDetailsFilter" class="ri-arrow-up-down-line filteredIcon"
                    (click)="applyComanyFilter()"></i> -->
                </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <p class="boldTxt">{{element?.supplierName}}</p>
                <p>{{element.supplierEmail}}</p>
                <p>{{element?.supplierPhoneNumber}}</p>
              </td>
            </ng-container>

            <ng-container matColumnDef="companyProfile">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Company Profile
                  <!-- <i *ngIf="!isComanyProfileFilter" class="ri-arrow-up-down-line unFilteredIcon"
                    (click)="applyProfileFilter()"></i>
                  <i *ngIf="isComanyProfileFilter" class="ri-arrow-up-down-line filteredIcon"
                    (click)="applyProfileFilter()"></i> -->
                </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <div [matMenuTriggerFor]="matMenuCP">
                  <p *ngIf="['PID','VENDOR_FINANCE'].includes(element.productType)" class="noOfSuppliers">
                    No. of Suppliers
                    <span class="boldTxt">: {{ element.noOfSuppliers }}</span>
                  </p>
                  <p *ngIf="['SID','DEALER_FINANCE','PO'].includes(element.productType)" class="noOfBuyers">
                    No. of Buyers
                    <span class="boldTxt">: {{ element.noOfBuyers }}</span>
                  </p>
                </div>
                <mat-menu #matMenuCP="matMenu" class="matMenuCPWrap">
                  <span class="headTxt">Buyers</span>
                  <ol>
                    <li>ABFL Pvt Ltd</li>
                    <li>Samrat Enterprises Pvt Ltd</li>
                    <li>Mogli Lab India Pvt Ltd</li>
                    <li>Credlix Pvt Ltd</li>
                  </ol>
                </mat-menu>
                <p>
                  Limit Applied For
                  <span class="boldTxt">: {{ element.totalFundingRequirement }}</span>
                </p>
              </td>
            </ng-container>

            <ng-container matColumnDef="productType">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Product Type
                  <i class="ri-arrow-down-s-line" [matMenuTriggerFor]="matMenuCP"></i>
                  <mat-menu #matMenuCP="matMenu" class="matMenuCPWrap" (closed)="onMenuClose()">
                    <span class="headTxt">Product type</span>
                    <ol>
                      <li *ngFor="let status of productTypeList">
                        <button mat-menu-item (click)="selectProductType(status.id)">
                          {{ status.name }}
                        </button>
                      </li>
                    </ol>
                  </mat-menu>
                </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <p class="boldTxt">{{ getProductTypeName(element.productType) }}</p>
              </td>
            </ng-container>   

            <ng-container matColumnDef="approvedAmount">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Approved Amount
                  <i *ngIf="!isApprovedAmountFilter" class="ri-arrow-up-down-line unFilteredIcon"
                    (click)="applyApprovedAmountFilter()"></i>
                  <i *ngIf="isApprovedAmountFilter" class="ri-arrow-up-down-line filteredIcon"
                    (click)="applyApprovedAmountFilter()"></i>
                </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <p class="boldTxt">{{element.productType}}</p>
              </td>
            </ng-container>

            <ng-container matColumnDef="leadStatus">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Lead Status
                  <i class="ri-arrow-down-s-line" [matMenuTriggerFor]="matMenuCP"></i>
                  <mat-menu #matMenuCP="matMenu" class="matMenuCPWrap" (closed)="onMenuClose()">
                    <span class="headTxt">Status</span>
                    <ol>
                      <li *ngFor="let status of leadStatusList">
                        <button mat-menu-item (click)="selectleadstatus(status.id)">{{ status.name }}</button>
                      </li>
                    </ol>
                  </mat-menu>
                </span>
                <!-- <span class="pr-20">
                  Lead Status
                  <i class="ri-arrow-down-s-line" [matMenuTriggerFor]="matMenuCP"></i>
                  <mat-menu #matMenuCP="matMenu" class="matMenuCPWrap" (closed)="onMenuClose()">
                    <span class="headTxt">status</span>
                    <ol>
                      <li>
                        <button mat-menu-item (click)="selectStusatOption('PID')">PID</button>
                      </li>
                      <li>
                        <button mat-menu-item (click)="selectStusatOption('SID')">SID</button>
                      </li>
                    </ol>
                  </mat-menu>
                </span> -->
              </th>
              <td mat-cell *matCellDef="let element">
                <span [ngClass]="shieldApi.getStatusClass(element.status)">{{element.status}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Status
                  <i class="ri-arrow-down-s-line" [matMenuTriggerFor]="matMenuCP"></i>
                  <mat-menu #matMenuCP="matMenu" class="matMenuCPWrap">
                    <span class="headTxt">Sub Status</span>
                    <ol>
                      <li *ngFor="let data of subStatusList">
                        <button mat-menu-item (click)="selectSubStatus(data.id)"> {{ data.name }}</button>
                      </li>
                    </ol>
                  </mat-menu>
                </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <p class="boldTxt">{{ getStatusTypeName(element.subStatus) }}</p>
                <!-- <p class="boldTxt">{{element.subStatus }}</p> -->
              </td>
            </ng-container>

            <ng-container matColumnDef="remark">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Remark</span>
              </th>
              <td mat-cell *matCellDef="let element">
                <p class="boldTxt">{{element.status}}</p>
              </td>
            </ng-container>

            <ng-container matColumnDef="assigneeDetails">
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20">
                  Assignee Details
                  <i class="ri-arrow-down-s-line" [matMenuTriggerFor]="matMenuCP"></i>
                  <mat-menu #matMenuCP="matMenu" class="matMenuCPWrap">
                    <span class="headTxt">Assignee Name</span>
                    <ol>
                      <li *ngFor="let activeuser of allActiveUserList">
                        <button mat-menu-item (click)="selectAssigneeName(activeuser.id)"> {{ activeuser.name
                          }}</button>
                      </li>
                    </ol>
                  </mat-menu>
                </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <p>{{element?.assignee?.name}}</p>

                <span *ngIf="element?.relationshipManager" class="rmTxt">RM:
                  {{element?.relationshipManager}}</span>
                <p>Assigned On <span class="boldTxt">: {{element?.assignedOn | date: 'dd-MMM-yyyy' }}</span></p>
              </td>
            </ng-container>

            <ng-container matColumnDef="action1" stickyEnd>
              <th mat-header-cell *matHeaderCellDef>
                <ng-container class="actionTh">
                  <span class="pr-20"> Action </span>
                  <!-- <mat-icon class="resetIcn" (click)="resetFilter()">autorenew</mat-icon> -->
                </ng-container>
              </th>
              <td mat-cell *matCellDef="let element">
                <p class="actionIcon">
                  <i class="blueTxt mr-10 ri-file-edit-line link" (click)="routeToDetailsPreviewPage(element.id)"
                    matTooltip="Edit Lead"></i>
                  <i class="blueTxt ri-user-add-line" (click)="changeAssignee(element)" matTooltip="Change Assignee"></i>
                </p>
              </td>
            </ng-container>

            <ng-container matColumnDef="action2" stickyEnd>
              <th mat-header-cell *matHeaderCellDef>
                <span class="pr-20"> Action </span>
              </th>
              <td mat-cell *matCellDef="let element">
                <button mat-button class="sendToLmsBtn" (click)="sendToLms(element.id)">Send To LMS
                  <mat-icon>skip_next</mat-icon></button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
          </table>
          <p *ngIf="!dataSource || !dataSource.data || dataSource.data.length === 0" class="no-record-found">
            No record found
          </p>
          <mat-paginator [length]="totalItems" [pageSize]="pageSize" [pageSizeOptions]="[10, 20, 30]"
            (page)="pageEvent($event)">
          </mat-paginator>

        </div>
      </mat-tab>
    </mat-tab-group>
  </main>
</section>