import { Component } from '@angular/core';
import { ShieldApiService } from './service/shield-api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isLoading: boolean;
 constructor(private shieldService:ShieldApiService) { }

  ngOnInit() {
    this.shieldService.isLoading$.subscribe(res => {
      setTimeout(() => {
        this.isLoading = res;
      }, 0);
    })
  }

}
