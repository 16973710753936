<div class="main-dialog-box">
    <div class="listingDialog">
        <h3><a><i (click)="dialogRef.close(false)" class="ri-close-line alignClose"></i></a></h3>
        <div class="heading">
            <h3>Deferred</h3>
        </div>
        <div class="date-div">
            <label class="date-label">Deferred Date</label>
            <mat-form-field appearance="outline" class="sid_input">
                <input matInput [matDatepicker]="picker" (focus)="picker.open()" [min]="todayDate" placeholder="Select Deferred date" [formControl]="deferredDate" readonly/>
                <mat-datepicker-toggle matSuffix [for]="picker">
                    <i class="ri-calendar-2-line"></i>
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="btn-footer">
            <button mat-raised-button type="button" class="btnSave" [disabled]="!deferredDate.value" (click)="deferredDoc()">Submit</button>
        </div>
    </div>
</div>