<div class="main-doc-container">
    <div class="header justify-content-between">
      <div>
        <span>Document Validation</span>
      </div>
      <div class="right d-flex align-items-center">
        <button type="button" class="btn-outline-primary save-cta" mat-stroked-button color="black"><i class="ri-save-2-fill"></i>Save</button>
        <button type="button" class="btn-outline-primary download-cta" mat-stroked-button color="black"><i class="ri-download-2-fill"></i>Download Uploaded File</button>
      </div>
    </div>
    <div class="mat-table-overflow default-table-height">
        <table
          mat-table
          class="mat-table-theme mat-table-bordered"
          [dataSource]="documentListDataSource">

          <ng-container matColumnDef="Documents" sticky>
            <th mat-header-cell *matHeaderCellDef class="sticky-left">
              <span class="pr-20">Documents </span>
            </th>
            <td mat-cell *matCellDef="let element;let i = index" class="sticky-left">
              
              <!-- <span class="link" *ngIf="checkArray(element.value)"><i class="ri-image-line"></i> {{ element.name }} </span>  -->
              <span class="link"><i class="ri-image-line"></i> GST File </span> 

              <!-- <span class="link" *ngIf="checkArray(element.value) && element.value[0].documentId; else elseBlock" (click)="openAllFilesPopUp(element.value[0].name)" > + {{ element.value.length }} Files</span>  -->
              <span class="link" > + 3 Files</span> 

              <!-- <ng-template #elseBlock>
                <span class="link" *ngIf="checkArray(element.value)">No Files Uploaded</span> 
              </ng-template> -->
              <!-- <span class="link" *ngIf="!checkArray(element.value)" (click)="downloadFile(element.value.documentId)"><i class="ri-image-line"></i> {{ element.name }} </span>  -->

            </td>
          </ng-container>

          <ng-container matColumnDef="Status">
            <th mat-header-cell *matHeaderCellDef>
              <span class="pr-20">Status </span>
            </th>
            <td mat-cell *matCellDef="let element;let i = index">
              <ng-container>
                <span class="pending">PENDING</span>
                <span style="color: black;"><b>12th Oct 2024</b></span>
              </ng-container>
              <!-- <ng-container *ngIf="checkArray(element.value) && element && element.value.length>0">
                <span class="pending" *ngIf ="element.value[0].status == 'PENDING' || !element.value[0].status"> {{ element.value[0].status? element.value[0].status: 'PENDING' }} </span>
                <span class="aprrove" *ngIf ="element.value[0].status == 'APPROVED'"> {{ element.value[0].status }} </span>
                <span class="approve" *ngIf ="element.value[0].status == 'SUBMITTED'"> {{ element.value[0].status }} </span>
                <span class="rejected" *ngIf ="element.value[0].status == 'REJECTED'"> {{ element.value[0].status }} </span>
                <span class="aprrove" *ngIf ="element.value[0].status == 'WAIVERED'"> {{ element.value[0].status }} </span>
                <span class="rejected" *ngIf ="element.value[0].status == 'DEFFERED'"> {{ element.value[0].status }} </span><br>
                <span style="color: black;"><b>{{element.value[0].deferredDate ? (element.value[0].deferredDate  | date: 'dd MMMM yyyy')  : null}}</b></span>
              </ng-container>
              <ng-container *ngIf="!checkArray(element.value) && element && element.value">
                <span class="pending" *ngIf ="element.value.status == 'PENDING'"> {{ element.value.status }} </span>
                <span class="aprrove" *ngIf ="element.value.status == 'APPROVED'"> {{ element.value.status }} </span>
                <span class="approve" *ngIf ="element.value.status == 'SUBMITTED'"> {{ element.value.status }} </span>
                <span class="rejected" *ngIf ="element.value.status == 'REJECTED'"> {{ element.value.status }} </span>
                <span class="aprrove" *ngIf ="element.value.status == 'WAIVERED'"> {{ element.value.status }} </span>
                <span class="rejected" *ngIf ="element.value.status == 'DEFFERED'">
                  {{ element.value.status }}</span><br>
                  <span style="color: black;"><b>{{element.value.deferredDate ? (element.value.deferredDate  | date: 'dd MMMM yyyy')  : null}}</b></span>
              </ng-container> -->
            </td>
          </ng-container>

          <ng-container matColumnDef="Remarks">
            <th mat-header-cell *matHeaderCellDef>
              <span class="pr-20">Under Writting Remarks </span>
            </th>
            <td mat-cell *matCellDef="let element;let i = index">
               <!-- <ng-container *ngIf="checkArray(element.value) && element && element.value.length>0">{{element.value[0].remarks}}</ng-container>
               <ng-container *ngIf="!checkArray(element.value) && element && element.value">{{element.value.remarks}}</ng-container> -->
            </td>
          </ng-container>

          <ng-container matColumnDef="Action" sticky>
            <th mat-header-cell *matHeaderCellDef class="sticky-right">
              <span>Action </span>
            </th>
            <td mat-cell *matCellDef="let element;let i = index" class="sticky-right">

              <!-- <div class="d-flex al-center flex-nowrap cta-box" *ngIf="hasAccess">
                <ng-container *ngIf="checkArray(element.value) && element && element.value.length>0">
                  <ng-container *ngIf="element.value[0].status == 'PENDING' || !element.value[0].status || element.value[0].status == 'WAIVERED' || element.value[0].status == 'DEFFERED'">
                    <button class="accept-cta" (click)="changeStatus(element.value[0].name,'WAIVERED')" type="button">Waiver </button>
                    <button class="reject-cta" (click)="openDefferedDialouge(element.value[0].name,'DEFFERED')" type="button"> Deferred</button>
                  </ng-container>
                <ng-container *ngIf="element.value[0].status == 'REJECTED'">
                    <button (click)="triggerFolderUpload()" class="download-cta"><i class="ri-upload-2-fill"></i> Reupload</button>
                    <input type="file" id="folderInput" (change)="handleFolderUpload($event,element.value[0].name)" webkitdirectory multiple hidden>
                </ng-container>

                </ng-container>
                <ng-container *ngIf="!checkArray(element.value) && element && element.value">
                  <ng-container *ngIf="element.value.status == 'PENDING' || element.value.status == 'WAIVERED' || element.value.status == 'DEFFERED'">
                    <button class="accept-cta" (click)="changeStatus(element.value.name,'WAIVERED')" type="button">Waiver </button>
                    <button class="reject-cta" (click)="openDefferedDialouge(element.value.name,'DEFFERED')" type="button"> Deferred</button>
                  </ng-container>
                  <ng-container *ngIf="element.value.status == 'REJECTED'">
                    <button (click)="changeFile(element.value.id,element.value.name)" class="download-cta"><i class="ri-upload-2-fill"></i> Reupload</button>
                  </ng-container>
                </ng-container>
              </div> -->
            </td>
          </ng-container>


          <tr
            mat-header-row
            *matHeaderRowDef="displayColumns1; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: displayColumns1"
          ></tr>
        </table>
      </div>
</div>