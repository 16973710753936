import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit } from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ShieldApiService } from 'src/app/service/shield-api.service';

@Component({
  selector: 'app-send-to-user',
  templateUrl: './send-to-user.component.html',
  styleUrls: ['./send-to-user.component.scss']
})
export class SendToUserComponent implements OnInit {
  email: any;
  name: any;
  contactDetails: any;
  url: any;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  emailList: any[] = [];
  allEmails: any = [];
  emailFormatError: boolean = false;
  emailexistError: boolean = false;
  emailNumbersError: boolean = false;
  constructor(private shieldApi: ShieldApiService, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    console.log(this.data);
    this.shieldApi.laneData.subscribe((res: any) => {
      console.log(res.applicationPreview.leadDetails);
      this.contactDetails = this.getLeadContactDetails(res.applicationPreview.leadDetails.contact[0])
    });
    this.email = this.contactDetails.email || this.data.lead.contact[0].email || null;
    this.email ? this.emailList.push({ name: this.email }) : '';
    this.name = this.contactDetails.name;
    this.url = this.data.url;
  }

  getLeadContactDetails = (lead: any) => {
    var contact = {
      "email": null as null,
      "phone": null as null,
      "name": null as null
    };
    if (lead != null ) {
      contact["email"] = lead.email
      contact['phone'] = lead.phone
      contact['name'] = lead.name
    }
    return contact;
  }

  getName(lead: any) {
    const obj = {
      name: ""
    }
    if (lead['data'] != null && lead['data'].length > 0) {
      lead['data'].find((element: any) => {
        if (element['field'] == "supplier_name") {
          obj.name = element['value'];
        }
      });
    }
    return obj.name;
  }

  sendToUser() {
    this.allEmails = this.emailList.map(x => x.name);
    const obj = {
      emails: this.allEmails,
      name: this.name,
      url: this.url
    }
    this.shieldApi.sentToUser(obj).subscribe((res: any) => {
      if (res && res['data']) {
        console.log(res);
      }
    });
  }

  add(event: MatChipInputEvent): void {
    const value = event.value;
    if (this.emailList.length < 5) {
      if (this.validateEmail(value)) {
        if (this.emailList.findIndex(x => x.name == value) == -1) {
          if ((value || '').trim()) {
            this.emailList.push({ name: value.trim() });
            event.value = null;
            this.emailFormatError = false;
            this.emailexistError = false;
            this.emailNumbersError = false;

            this.email = this.allEmails;
          }
        } else {
          this.emailexistError = true;
          this.emailFormatError = false;
          this.emailNumbersError = false;
        }
      } else {
        this.emailFormatError = true;
        this.emailexistError = false;
        this.emailNumbersError = false;
      }
    } else {
      this.emailNumbersError = true;
      this.emailFormatError = false;
      this.emailexistError = false;
    }
  }

  validateEmail(email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }

  remove(fruit: any): void {
    const index = this.emailList.indexOf(fruit);

    if (index >= 0) {
      this.emailList.splice(index, 1);
      this.emailNumbersError = false;
    }
  }
}
