<div class="contentWrapper">
	<section>
		<div class="main-head-wrap">
			<div class="heading" fxLayout="row" fxLayoutAlign="start center">
				<i class="ri-arrow-left-line" (click)="goToBack()"></i>
				<span class="headLbl">Domestic</span>
				<span class="name"> {{ this.laneData?.leadOwner?.name }}</span>
			</div>
		</div>
	</section>

	<section class="lead-summary">
		<div class="eximInfoSummary">
			<div class="lftSection">
				<div class="commonItems">
					<span class="titleInfo">Lead Summary</span>
					<span class="sourceInfo">
						<span class="lbl">Source :</span>
						<span class="value">Domestic-{{ this.laneData?.displaySequence }}</span>
					</span>
				</div>
				<div class="commonItems">
					<span class="titleInfo">Contact Details</span>
					<span class="compName">{{ this.primaryContact?.name }}</span>
					<div class="d-flex">
						<ng-container>
							<span class="phoneNo">{{ this.primaryContact?.phone }},</span>
						</ng-container>
						<span class="emailTxt">{{ this.primaryContact?.email }}</span>
					</div>
				</div>
			</div>

			<div class="rhtOtherDetails">
				<span class="headTitleTxt">Other Details</span>
				<div class="otherDetailsContent">
					<div class="commonODInfo">
						<i class="ri-user-follow-line spIcon"></i>
						<div class="cibilScore">
							<span class="textWithIcon">
								{{ buyerLength }}
							</span>
							<span class="bottomTxt"> No. of {{ uploadTypeName }} </span>
						</div>
					</div>

					<div class="commonODInfo">
						<i class="ri-money-rupee-circle-line spIcon"></i>
						<div class="cibilScore">
							<span class="textWithIcon"> {{ buyerLimit }} </span>
							<span class="bottomTxt"> Limit Required </span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>


	<section class="losMFstLabelWrap">
		<button *ngIf="currentlaneName == 'gst-module'" class=" downloadGStBtn btn-outline-primary watcher-cta" mat-stroked-button color="black" (click)="downloadGStReport()">
			<span class="material-symbols-outlined">
				download
			</span>Download Report </button>
		<div class="w-20">
			<div class="input-group">
				<mat-form-field class="mat-select" appearance="outline">
					<mat-select placeholder="Stage Movement" [(ngModel)]="stageMovement"
						(selectionChange)="changeStageMovement($event)">
						<mat-option class="mat-option" value="PENDING_WITH_CREDIT">Pending With Credit</mat-option>
						<mat-option class="mat-option" value="PENDING_WITH_SALES">Pending With Sales</mat-option>
						<mat-option class="mat-option" value="PENDING_WITH_OPERATIONS">Pending With Operations</mat-option>
					</mat-select>
				</mat-form-field>
			</div>
		</div>
		<button class="btn-outline-primary watcher-cta" mat-stroked-button color="black" (click)="watcherPopup()"><i
				class="ri-eye-line"></i>Watcher</button>
		<button class="btn-outline-primary watcher-cta" mat-stroked-button color="black" (click)="onSideTabToggle()">
			<i class="ri-user-line"></i>
			{{ laneData?.assignee?.name }}
		</button>
		<button class="btn-outline-primary watcher-cta" mat-stroked-button color="black" (click)="comments()"><i
				class="ri-chat-1-line"></i>Comment</button>
		<ng-container *ngIf="laneData && laneData.status">
			<button class="btn-outline-primary watcher-cta" mat-stroked-button color="black"
				(click)="leadAction('reject')" [disabled]="isApprovalDisable()">Reject</button>
			<button class="btn-outline-primary watcher-cta approved" mat-stroked-button color="black"
				(click)="leadAction('approve')" [disabled]="isApprovalDisable()">Approve</button>
		</ng-container>
	</section>
	<section class="borrowerDetaisHead">
		<h3>Lead Details  <i class="ri-arrow-right-s-line"></i> {{getSelectedPageName()}} <span *ngIf="bureauReportSubPath"><i class="ri-arrow-right-s-line"></i> {{getBureauSubPathName()}}</span></h3>
	</section>

	<div class="h-100 detailTwoSecLeadWrap">
		<div class="sidebar">
			<ul>
				<li *ngFor="let lane of totalLanesList">
					<a *ngIf="!(lane.routePath == 'scoreCard' && uploadTypeName == 'Supplier') && lane.laneName!='Bureau Report'"
						(click)="reRoute(lane.routePath)" routerLinkActive="active">
						<span><span class="alignIcon"><i [ngClass]="checkApproval(lane)"></i></span> {{ lane.laneName }}</span> <i class="ri-arrow-right-s-line"></i>
					</a>

					<a *ngIf="lane.laneName=='Bureau Report'" [matMenuTriggerFor]="bureauReport">
						<span><span class="alignIcon"><i [ngClass]="checkApproval(lane)"></i></span> {{ lane.laneName }}</span> <i class="ri-arrow-right-s-line"></i>
					</a>

					<mat-menu #bureauReport="matMenu" class="bureauReportMat" xPosition="before" yPosition="above">
						<button mat-menu-item [matMenuTriggerFor]="consumerMenu">Consumer</button>
						<button mat-menu-item [matMenuTriggerFor]="commercialMenu">Commercial</button>
					</mat-menu>

					<mat-menu #consumerMenu="matMenu" class="bureauReportMatNested">
						<button mat-menu-item (click)="bureauRoute('consumer-info')" routerLinkActive="active">Consumer
							Info</button>
						<button mat-menu-item (click)="bureauRoute('enquiry-bureau-report')"
							routerLinkActive="active">Enquiry</button>
						<button mat-menu-item (click)="bureauRoute('summary-bureau-report')"
							routerLinkActive="active">Summary</button>
						<button mat-menu-item (click)="bureauRoute('accounts-bureau-report')"
							routerLinkActive="active">Accounts</button>
					</mat-menu>

					<mat-menu #commercialMenu="matMenu" class="bureauReportMatNested">
						<button mat-menu-item (click)="bureauRoute('borrower-bureau-report')"
							routerLinkActive="active">Borrower Profile</button>
						<button mat-menu-item (click)="bureauRoute('transunion-cibil-report')"
							routerLinkActive="active">TransUnion CIBIL Rank</button>
						<button mat-menu-item (click)="bureauRoute('credit-profile-summary')"
							routerLinkActive="active">Credit Profile Summary</button>
						<button mat-menu-item (click)="bureauRoute('credit-facility-details')"
							routerLinkActive="active">Credit Facility Details</button>
						<button mat-menu-item (click)="bureauRoute('enquiry-details')" routerLinkActive="active">Enquiry
							Details</button>
					</mat-menu>

				</li>
			</ul>
		</div>
		<div class="right-sec">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>